import React from 'react'
import { GxForm } from '@garpix/garpix-web-components-react'
import ModalView from '../../../ModalView'
import { BUTTON_THEME } from '../../../../const'
import Input from '../../../Input'
import styles from '../styles/index.module.scss'
import useTranslate from '../../../../hooks/useTranslate'

const inputs = [
  {
    id: 0,
    name: 'old_password',
    label: {
      id: 'modals.changePassword.oldPassword',
      defaultMessage: 'Старый пароль'
    }
  },
  {
    id: 1,
    name: 'new_password',
    label: {
      id: 'modals.changePassword.newPassword',
      defaultMessage: 'Новый пароль'
    }
  }, {
    id: 2,
    name: 'password2',
    label: {
      id: 'modals.changePassword.password2',
      defaultMessage: 'Повторите новый пароль'
    }
  }
]

const ChangePassword = ({ formik, handleClose }) => {
  const { handleSubmit, handleChange, handleBlur, touched, errors, isValid, dirty, isSubmitting } = formik
  const { translate, intl } = useTranslate()
  return (
    <GxForm onGx-submit={handleSubmit} data-cy='change-password-form'>
      <ModalView.Title>
        {intl.formatMessage({
          id: 'changePassword',
          defaultMessage: 'Изменить пароль'
        })}
      </ModalView.Title>
      {
        inputs.map(({ label, name, id }) => (
          <div key={id} className={styles.change_password__input}>
            <Input
              label={intl.formatMessage(label)}
              name={name}
              type='password'
              onGx-input={handleChange}
              onGx-blur={handleBlur}
              error={touched[name] && errors[name]}
              errorText={translate(errors[name])}
              required
            />
          </div>
        ))
      }
      <ModalView.Buttons>
        <ModalView.Buttons.Button data-cy='modal-cancel-btn' theme={BUTTON_THEME.STROKE_ICON.SMALL} onClick={handleClose}>
          {intl.formatMessage(
            {
              id: 'cancel',
              defaultMessage: 'Отменить'
            }
          )}
        </ModalView.Buttons.Button>
        <ModalView.Buttons.Button
          data-cy='modal-accept-btn'
          type='submit'
          theme={BUTTON_THEME.FILL_ICON.SMALL}
          disabled={isSubmitting || !(isValid && dirty)}
        >
          {
            intl.formatMessage(
              {
                id: 'change',
                defaultMessage: 'Изменить'
              }
            )
          }
        </ModalView.Buttons.Button>
      </ModalView.Buttons>
    </GxForm>
  )
}

export default ChangePassword
