import React from 'react'
import TariffBanner from '../../views/TariffBanner'
import { useStoreon } from 'storeon/react'
import usePage from '../../hooks/usePage'
import { useIntl } from 'react-intl'

const TariffBannerComponent = ({ isShow }) => {
  const { currentUser } = useStoreon('currentUser')
  const { is_free: isFree, is_trial: isTrial } = currentUser?.current_tariff || {}
  const { baseSlug } = usePage()
  const intl = useIntl()

  const tariffExpiration = currentUser?.current_tariff?.is_change_color

  if (!isShow) {
    return null
  }
  if (isFree) {
    return (
      <TariffBanner
        titleLink={intl.formatMessage({
          id: 'yourTariffHasExpired',
          defaultMessage: 'У Вас закончилось действие тарифа'
        })}
        labelLink={intl.formatMessage({
          id: 'choosePlan',
          defaultMessage: 'Выбрать тариф'
        })}
        link={baseSlug.pageTariffs}
      />
    )
  }

  if (isTrial) {
    return (
      <TariffBanner
        titleLink={intl.formatMessage({
          id: 'youHaveATrialPlan',
          defaultMessage: 'У Вас пробный тариф'
        })}
        labelLink={intl.formatMessage({
          id: 'choosePlan',
          defaultMessage: 'Выбрать тариф'
        })}
        link={baseSlug.pageTariffs}
      />
    )
  }

  if (tariffExpiration) {
    return (
      <TariffBanner
        titleLink={intl.formatMessage({
          id: 'yourPlanIsAboutToExpire',
          defaultMessage: 'Срок действия вашего тарифа подходит к концу'
        })}
        labelLink={intl.formatMessage({
          id: 'extendTariff',
          defaultMessage: 'Продлить тариф'
        })}
        link={baseSlug.pageTariffs}
      />
    )
  }
  return null
}

export default TariffBannerComponent
