import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import PageMenuDropdown from '../../../components/PageMenuDropdown'
import WrapperTitle from '../../../components/CargoSpaceComponents/WrapperTitle'
import DemoField from '../../DemoTableField'
import OwnerTableField from '../../OwnerTableField'
import UpdateTableField from '../../UpdateTableField'
import { commonAcount } from '../../../images'
import style from '../styles/index.module.scss'
import { formattingDate } from '../../../utils'

const commonUser = { thumbnail: commonAcount, first_name: 'Общий' }

const content = {
  project: (data) => (
    <div className={style.mobile_card_footer_created}>
      <span className={style['mobile_card_footer_created--text']}> Создано: </span>
      {formattingDate(data?.created_at || '')}
    </div>
  ),
  cargo_space: (props) => (
    <div className={style.mobile_card_footer_type}>
      Тип: <div className={style.mobile_card_footer_type_image}>{props.icon}</div>
    </div>
  ),
  cargo: (data) => (
    <div>
      {data.length}х{data.height}х{data.width} мм. | {data.mass} кг
    </div>
  )
}

const MobileTableCard = ({ item, handleClick, handleContextMenu, type, results, ...props }) => {
  const {
    iconCS,
    reload,
    loadData,
    actions,
    activePage,
    handleCheckID,
    checkID,
    handleResetCheck
  } = props

  if (!item.project_owner) item.project_owner = commonUser

  const icon = (
    <GxIcon
      src={iconCS[item.cargo_space_type]}
      className={style.cargo_space__icon}
    />
  )
  const data = {
    ...item,
    icon,
    type,
    handleCheckID,
    checkID,
    owner: item.project_owner
  }

  const actionsCargoSpace = actions[item.cargo_space_type] || actions

  return (
    <tr
      id={item.id}
      data-cy={`${type}-update-link`}
      className={style.mobile_card}
      onClick={handleClick}
      onContextMenu={handleContextMenu}
    >
      <td className={style.mobile_card_header}>
        <WrapperTitle {...data}>
          <OwnerTableField
            {...data}
            {...item.project_owner}
            src={item.project_owner.thumbnail}
          />
        </WrapperTitle>
        <div className={style['mobile_card_header--right']}>
          <DemoField demo={data.demo} />
          <PageMenuDropdown
            reload={reload}
            loadData={loadData}
            type={type}
            actions={actionsCargoSpace}
            elementId={item.id}
            element={item}
            results={results}
            activePage={activePage}
            reset={handleResetCheck}
          />
        </div>
      </td>
      <td className={style.mobile_card_title}>
        <span>{data.title}</span>
      </td>
      <td className={style.mobile_card_footer}>
        {content[type](data)}
        <div className={style.mobile_card_footer_right}>
          <UpdateTableField
            date={item.updated_at}
            lastChangedUser={item.last_changed_user}
          />
        </div>
      </td>
    </tr>
  )
}
export default MobileTableCard
