export const INITIAL_CARGO = {
  info: { material: '' },
  type: '',
  color: '#a6001a',
  title: '',
  article: '',
  cargo_id: '',
  length: '',
  width: '',
  height: '',
  mass: '',
  stacking: true,
  is_rotate_y: false,
  stacking_limit: '',
  turnover: true,
  margin_length: 0,
  margin_width: 0,
  count: 1
}

export const INITIAL_CARGO_GROUP = {
  group_id: '',
  title: '',
  pallet: null,
  cargoes: []
}

export const CARGO_ITEM_FIELDS = [
  {
    id: 0,
    name: 'article',
    label: {
      id: 'article',
      defaultMessage: 'Артикул'
    },
    placeholder: '',
    required: null,
    component: 'input'
  },
  {
    id: 1,
    name: 'length',
    label: {
      id: 'space.field.length',
      defaultMessage: 'Длина (мм)'
    },
    placeholder: '0',
    required: true,
    component: 'inputNumbers'
  },
  {
    id: 2,
    name: 'width',
    label: {
      id: 'space.field.width',
      defaultMessage: 'Ширина (мм)'
    },
    placeholder: '0',
    required: true,
    component: 'inputNumbers'
  },
  {
    id: 3,
    name: 'height',
    label: {
      id: 'space.field.height',
      defaultMessage: 'Высота (мм)'
    },
    placeholder: '0',
    required: true,
    component: 'inputNumbers'
  },
  {
    id: 4,
    name: 'mass',
    label: {
      id: 'space.field.mass',
      defaultMessage: 'Масса (кг)'
    },
    placeholder: '0',
    required: true,
    component: 'inputNumbers'
  }
]

export const CARGO_ITEM_CHECKBOXS = [
  {
    id: 0,
    name: 'turnover',
    tooltipContent: {
      id: 'cant',
      defaultMessage: 'Кантовать'
    }
  },
  {
    id: 1,
    name: 'stacking',
    tooltipContent: {
      id: 'stack',
      defaultMessage: 'Штабелировать'
    }
  },
  {
    id: 2,
    name: 'is_rotate_y',
    tooltipContent: {
      id: 'limitVerticalRotation',
      defaultMessage: 'Поворот вокруг вертикальной оси'
    }
  }
]
