import * as yup from 'yup'
import { INTL_ERROR_MESSAGE } from '../../const'
import { getValidateText } from '../ValidateText'

function checkOnEmpty (errMes) {
  return this.test('checkOnEmpty', errMes, function (value) {
    const { path, createError, from: initialState } = this
    if (initialState[0].value[path] === undefined) {
      return createError({ path, message: errMes })
    }
    return true
  })
}

yup.addMethod(yup.number, 'checkOnEmpty', checkOnEmpty)

const SettingSchema = yup.object().shape({
  first_name: yup.string().trim().max(150, getValidateText('string', 'max', 150)).required(INTL_ERROR_MESSAGE.REQUIRED),
  last_name: yup.string().trim().max(150, getValidateText('string', 'max', 150)).required(INTL_ERROR_MESSAGE.REQUIRED),
  phone: yup.string().min(11, INTL_ERROR_MESSAGE.REQUIRED).required(INTL_ERROR_MESSAGE.REQUIRED),
  zip_code: yup.string().trim().max(12, getValidateText('string', 'max', 12)),
  tax_identify: yup.string().trim().max(12, getValidateText('string', 'max', 12))
})

export default SettingSchema
