export const BUFER_SIZE = 15000;
export const MESSAGES = {
  length: {
    id: 'length',
    defaultMessage: 'Длина'
  },
  width: {
    id: 'width',
    defaultMessage: 'Ширина'
  },
  height: {
    id: 'height',
    defaultMessage: 'Высота'
  },
  mass: {
    id: 'mass',
    defaultMessage: 'Масса'
  },
  stacking: {
    id: 'stacking',
    defaultMessage: 'Штабелирование',
  },
  canting: {
    id: 'canting',
    defaultMessage: 'Кантование'
  },
  rotate: {
    id: 'rotateAxis',
    defaultMessage: 'Повернуть по оси'
  },
  yes: {
    id: 'yes',
    defaultMessage: 'Да'
  },
  no: {
    id: 'no',
    defaultMessage:'Нет'
  }

}

export const ENUM_VECTOR3 = {
  0: 'x',
  1: 'y',
  2: 'z',
};

export const MESURES_EN = {
  MM: 'MM',
  M: 'M',
  KG: 'KG',
  SEC: 'SEC',
  mm: 'mm',
  m: 'm',
  kg: 'kg',
  sec: 'sec',
};

export const MESURES_RU = {
  MM: 'ММ',
  M: 'М',
  KG: 'КГ',
  SEC: 'СЕК',
  mm: 'мм',
  m: 'м',
  kg: 'кг',
  sec: 'сек',
};

export const RADIANS = {
  RAD_0: 0,
  RAD_30: Math.PI / 6,
  RAD_45: Math.PI / 4,
  RAD_60: Math.PI / 3,
  RAD_90: Math.PI / 2,
  RAD_120: (2 * Math.PI) / 3,
  RAD_135: (3 * Math.PI) / 4,
  RAD_150: (5 * Math.PI) / 6,
  RAD_180: Math.PI,
  RAD_210: (7 * Math.PI) / 6,
  RAD_225: (5 * Math.PI) / 4,
  RAD_240: (4 * Math.PI) / 3,
  RAD_270: (3 * Math.PI) / 2,
  RAD_300: (5 * Math.PI) / 3,
  RAD_315: (7 * Math.PI) / 4,
  RAD_360: (11 * Math.PI) / 6,
};

export const MAIN_NUMBERS = {
  NUM_100000: 100000,
  NUM_10000: 10000,
  NUM_5000: 5000,
  NUM_1000: 1000,
  NUM_500: 500,
  NUM_300: 300,
  NUM_100: 100,
  NUM_50: 50,
  NUM_10: 10,
  NUM_5: 5,
  NUM_1: 1,
  NUM_0: 0,
  NUM_05: 0.5,
  NUM_01: 0.1,
  NUM_005: 0.05,
  NUM_001: 0.01,
  NUM_0005: 0.005,
  NUM_0001: 0.001,
  NUM_2: 2,
};

export const CUSTOM_MESSAGE = {
  error: `Что то пошло не так...</br> Попробуйте чуть позже.`,
};

export const SCENE_ELEMENTS = {
  BOX: 'BOX',
  UNPACKED_BOX: 'UNPACKED_BOX',
  CONTAINER_FLOOR: 'CONTAINER_FLOOR',
  CONTAINER: 'CONTAINER',
  FLOOR: 'FLOOR',
  RULER_BOTTOM: 'RULER_BOTTOM',
  RULER_TOP: 'RULER_TOP',
  RULER_TITLE_BOTTOM: 'RULER_TITLE_BOTTOM',
  RULER_TITLE_TOP: 'RULER_TITLE_TOP',
  LOADING_AREA: 'LOADING_AREA',
};

export const SPACE_TYPES = {
  VAN: 'van',
  TRUCK: 'truck',
  PALLET: 'pallet',
  CONTAINER: 'container',
  TRACTOR: 'tractor',
};

export const LABELS = {
  [SPACE_TYPES.VAN]: 'Фургон',
  [SPACE_TYPES.PALLET]: 'Палета',
  [SPACE_TYPES.CONTAINER]: 'Контейнер',
  [SPACE_TYPES.TRUCK]: 'Фура',
};

export const SCALE_LOADING_SIZE = {
  [SPACE_TYPES.VAN]: 0.431,
  [SPACE_TYPES.TRUCK]: 0.631,
  [SPACE_TYPES.PALLET]: 0.81,
  [SPACE_TYPES.CONTAINER]: 0,
};

export const PALLET_OFFESET_Y = 0.155

export const REST_LOADING_SIZE = {
  [SPACE_TYPES.VAN]: MAIN_NUMBERS.NUM_1 -  SCALE_LOADING_SIZE[[SPACE_TYPES.VAN]],
  [SPACE_TYPES.TRUCK]:  MAIN_NUMBERS.NUM_1 -  SCALE_LOADING_SIZE[[SPACE_TYPES.TRUCK]],
  [SPACE_TYPES.PALLET]:  MAIN_NUMBERS.NUM_1 -  SCALE_LOADING_SIZE[[SPACE_TYPES.PALLET]],
  [SPACE_TYPES.CONTAINER]:  MAIN_NUMBERS.NUM_1 -  SCALE_LOADING_SIZE[[SPACE_TYPES.CONTAINER]]
};

export const REAL_LOADING_SIZE = {
  [SPACE_TYPES.VAN]: MAIN_NUMBERS.NUM_1 +  REST_LOADING_SIZE[[SPACE_TYPES.VAN]],
  [SPACE_TYPES.TRUCK]:  MAIN_NUMBERS.NUM_1 +  REST_LOADING_SIZE[[SPACE_TYPES.TRUCK]],
  [SPACE_TYPES.PALLET]:  MAIN_NUMBERS.NUM_1 +  REST_LOADING_SIZE[[SPACE_TYPES.PALLET]],
  [SPACE_TYPES.CONTAINER]:  MAIN_NUMBERS.NUM_1 +  REST_LOADING_SIZE[[SPACE_TYPES.CONTAINER]]
};

export const STATE_COLLECT = {
  DEFAULT: 'DEFAULT',
  ACTIVE: 'ACTIVE',
  WARNING: 'WARNING',
};

export const COLORS = {
  BLACK: '#000000',
  WHITE: '#ffffff',
  LINE_CONTAINER: '#057FF7',
  FLOOR_CONTAINER: '#464646',
  RED: '#ff0000',
  GREEN: '#008000',
  YELLOW: '#FFFF00',
  BLUE: '#0000FF',
  DEEP_GRAY: '#242629',
  LIGHT_GRAY: '#DCDCDC',
  SKY: '#fefefe',
  GROUND: '#5782d2',
  RULER_MEASUREMENT: { r: 256, g: 0, b: 0, a: 1 },
};

export const OPACITY = {
  OPASITY_0: 0,
  OPASITY_01: 0.1,
  OPASITY_02: 0.2,
  OPASITY_03: 0.3,
  OPASITY_04: 0.4,
  OPASITY_05: 0.5,
  OPASITY_06: 0.6,
  OPASITY_07: 0.7,
  OPASITY_08: 0.8,
  OPASITY_09: 0.9,
  OPASITY_1: 1,
};

export const EMPTY_CONSTS = {
  ARR: [],
  OBJ: {},
  STR: '',
  FALSE: false,
  TRUE: true,
  NULL: null,
  UNDEFINED: undefined,
  UNIT: MAIN_NUMBERS.NUM_1,
  ZERO: MAIN_NUMBERS.NUM_0,
  FUNC: () => {},
};

export const UNIT_3_ARR = [
  MAIN_NUMBERS.NUM_1,
  MAIN_NUMBERS.NUM_1,
  MAIN_NUMBERS.NUM_1,
];
export const ZERO_3_ARR = [
  MAIN_NUMBERS.NUM_0,
  MAIN_NUMBERS.NUM_0,
  MAIN_NUMBERS.NUM_0,
];
export const UNIT_OBJ_COORDS = {
  x: 1,
  y: 1,
  z: 1,
};
export const ZERO_OBJ_COORDS = {
  x: 0,
  y: 0,
  z: 0,
};
export const UNIT_OBJ_SIZES = {
  length: 1,
  height: 1,
  width: 1,
};
export const ZERO_OBJ_SIZES = {
  length: 0,
  height: 0,
  width: 0,
};

export const RULERS_DATA = {
  FONT_FACE: 'Arial',
  FONT_SIZE: 90,
};

export const TYPES_RULER = {
  horizontal: 'horizontal',
  vertical: 'vertical',
};

export const NAME_CONTAINER_SIDE = [
  'FrontSideWall',
  'BackSideWall',
  'Ceiling',
  'Floor',
  'LeftSideWall',
  'RightSideWall',
];

export const NAME_CONTAINER_LINE = [
  'FrontSideRightLineH',
  'FrontSideLeftLineH',
  'BackSideRightLineH',
  'BackSideLeftLineH',
  'LeftSideLine',
  'RightSideLine',
  'FrontSideTopLineX',
  'BackSideTopLineX',
];

export const LINE_DIRECTION = {
  positive: {
    small: 0.2,
    middle: 0.3,
    large: 0.5,
  },
  negative: {
    small: -0.2,
    middle: -0.3,
    large: -0.5,
  },
};
