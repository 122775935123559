/* eslint-disable camelcase */
import { GxIcon } from '@garpix/garpix-web-components-react'
import PhotoChange from '../PhotoChange'
import classNames from 'classnames'
import React from 'react'
import {
  USER_ROLE,
  BUTTON_VARIANT,
  BUTTON_THEME,
  COMPANY_PROFILE_INFO
} from '../../const'
import { historyIcon, save, starIcon, userIcon, editIcon } from '../../images'
import Tooltip from '../Tooltip'
import Button from '../Button'
import style from './styles/usertype.module.scss'
import { useIntl } from 'react-intl'

const user = {
  user: {
    label: {
      id: 'user',
      defaultMessage: 'Пользователь'
    },
    icon: userIcon
  },
  partner: {
    label: {
      id: 'partner',
      defaultMessage: 'Партнер'
    },
    icon: starIcon
  }
}

const typeIcon = {
  confirmed: {
    icon: save,
    tooltip: {
      id: 'confirmed',
      defaultMessage: 'Подтверждено'
    }
  },
  unconfirmed: {
    icon: historyIcon,
    tooltip: {
      id: 'awaitingConfirmation',
      defaultMessage: 'Ожидает подтверждения'
    }
  }
}

const UserType = ({
  components,
  handelChangeShowBlocks,
  handelOpenChangePhoto,
  profileInfo,
  handleOpenModal
}) => {
  const actionButton = components.find(
    (component) => component.component_model === 'ActionButtonComponent'
  )
  const { role_type, partner_confirm, thumbnail, company } = profileInfo || {}
  const intl = useIntl()
  const handlePartner = () => handleOpenModal(actionButton.object.form_type)

  const checkBtnAndRoleType = actionButton && role_type !== USER_ROLE.PARTNER
  const showPartnerBtn = company
    ? checkBtnAndRoleType && company?.role?.role_type === COMPANY_PROFILE_INFO.owner
    : checkBtnAndRoleType

  if (!profileInfo) return null

  return (
    <div className={style.type}>
      <PhotoChange photo={thumbnail} handelOpenChangePhoto={handelOpenChangePhoto} />
      <div className={style.type__info}>
        <div className={style.type__labels}>
          <div className={style.type__labels__blockLabels}>
            <p className={style.type__labels_title}>
              <span>
                <GxIcon
                  src={user[role_type].icon}
                  alt='Тип учетной записи'
                  className={style.type__labels_icon}
                />
              </span>
              {intl.formatMessage(user[role_type].label)}:
            </p>
            {partner_confirm && (
              <Tooltip hoist content={intl.formatMessage(typeIcon[partner_confirm].tooltip)}>
                <GxIcon
                  src={typeIcon[partner_confirm].icon}
                  alt='Тип учетной записи'
                  className={classNames({
                    [style['type__labels_icon-confirmed']]: true,
                    [style['type__labels_icon-yellow']]:
                      partner_confirm === 'unconfirmed'
                  })}
                />
              </Tooltip>
            )}
          </div>
          {role_type && (
            <h6 className={style.type__labels_value}>
              {profileInfo.first_name} {profileInfo.last_name}
            </h6>
          )}
        </div>
        {showPartnerBtn && (
          <div className={style.type__buttons}>
            <Button
              variant={BUTTON_VARIANT.TEXT}
              theme={BUTTON_THEME.TRANSPARENT_WITH_ICON.DEFAULT}
              onClick={handlePartner}
              className={style.type__buttons_partner}
            >
              {
                intl.formatMessage({
                  id: 'becomePartner',
                  defaultMessage: 'Стать партнером'
                })
              }
            </Button>
          </div>
        )}
      </div>
      <Button
        caret
        className={style.type__edit_profile}
        onClick={handelChangeShowBlocks}
        variant={BUTTON_VARIANT.TEXT}
        theme={BUTTON_THEME.TRANSPARENT_WITH_ICON.DEFAULT}
      >
        <GxIcon src={editIcon} />
      </Button>
    </div>
  )
}

export default UserType
