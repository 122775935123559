import React, { useEffect, useRef } from 'react'
import { zoomPhoto } from '../../../../utils'
import ModalView from '../../../ModalView'
import styles from '../styles/index.module.scss'

/**
 * @view
 * * Компонент для отображения фото ГП с его заполняемыми параметрами.

 * @prop {string} image         ссылка на фото ГП
 * @prop {Object} formikValues  Данные редактируемого ГП
 *
 */

const ShowCargoSpaceView = ({ image, formikValues }) => {
  const figureRef = useRef(null)

  useEffect(() => {
    if (figureRef.current) {
      figureRef.current.style.backgroundImage = `url('${image}')`
    }
  }, [figureRef])

  return (
    <>
      <ModalView.Title>Расположение осей</ModalView.Title>
      {formikValues?.axles && <p>Количество осей: {formikValues?.axles.length}</p>}
      <figure
        ref={figureRef}
        onTouchMove={zoomPhoto}
        onTouchStart={zoomPhoto}
        className={styles.cargo_space__zoom}
      >
        <img src={image} alt='Cargo Illustration' />
      </figure>
      <p className={styles.cargo_space__text}>Нажмите, чтобы приблизить</p>
    </>
  )
}

export default ShowCargoSpaceView
