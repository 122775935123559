
const avatar = (src) => {
  return (
    src && {
      alignItems: 'center',
      display: 'flex',
      ':before': {
        backgroundImage: `url(${src})`,
        borderRadius: '50%',
        backgroundSize: 'contain',
        content: '" "',
        display: 'block',
        marginRight: 10,
        height: 24,
        width: 24,
        minWidth: 24
      }
    }
  )
}

const baseCustomStyles = {
  control: (base, states) => {
    return ({
      ...base,
      width: '100%',
      height: '100%',
      border: 'none',
      paddingRight: '30px',
      boxShadow: states.isFocused && '0 0 0 1px var(--gx-color-input-hover)'
    })
  },
  indicatorSeparator: () => ({
    display: 'none'
  }),
  valueContainer: (base) => ({
    ...base,
    paddingLeft: '10px'
  }),

  option: (base, state) => {
    const { data, selectProps } = state
    return {
      ...base,
      ...avatar(data?.img),
      backgroundColor:
        selectProps.value?.id === data.id
          ? base.backgroundColor
          : 'var(--gx-color-white)',
      color: '#454e54',
      ':hover': {
        backgroundColor: 'var(--gx-color-list-hover)'
      }
    }
  },

  placeholder: (base) => ({
    ...base,
    color: '#707070',
    fontWeight: 'normal',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    lineHeight: '17px'
  }),
  singleValue: (base, { data }) => ({
    ...base,
    ...avatar(data?.img),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
    color: '#454e54'
  }),
  menu: (base) => ({
    ...base,
    zIndex: 100
  }),
  menuList: (base) => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '4px',
      height: '0px'
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '::-webkit-scrollbar-thumb': {
      background: 'var(--gx-color-input-hover)',
      borderRadius: '100px'
    }
  })
}

export const animationSelectMenuStyles = {
  menu: (base, state) => {
    return {
      ...base,
      '@keyframes fade-show': {
        '0%': { opacity: '0', transform: 'translateY(-30px)' },
        '100%': { opacity: '1', transform: 'translateY(0px)' }
      },
      '@keyframes fade-hide': {
        '0%': { opacity: '1', transform: 'translateY(0px)' },
        '100%': { opacity: '0', transform: 'translateY(-30px)' }
      },
      zIndex: 100,
      animation: state.selectProps.isCloseAnimation
        ? 'fade-hide var(--gx-transition-medium) ease-in-out forwards'
        : 'fade-show var(--gx-transition-medium) ease-in-out forwards'
    }
  }
}

export default baseCustomStyles
