import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { Link } from 'react-router-dom'
import { attentionBanner } from '../../images'
import { LINK_TARGET } from '../../const'
import styles from './styles/index.module.scss'

const TariffBanner = ({ link, labelLink, titleLink }) => {
  return (
    <div className={styles.banner}>
      <div className={styles.banner__content}>
        <div className={styles.content}>
          <GxIcon src={attentionBanner} className={styles.content__icon} />
          <h3 className={styles.content__text}>
            {titleLink}
          </h3>
        </div>
        <Link
          className={styles.content__button} to={link} target={LINK_TARGET.BLANK}
          rel='noopener noreferrer'
        >
          {labelLink}
        </Link>
      </div>
    </div>
  )
}

export default TariffBanner
