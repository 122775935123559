import intl from '../Intl'
export const getValidateText = (value, property, num) => {
  const text = {
    title: {
      min: (num) => intl.formatMessage({
        id: 'schemes.messages.minTitle',
        defaultMessage: 'Длина названия не может быть меньше {num} символов.'
      }, { num }),
      max: (num) => intl.formatMessage({
        id: 'schemes.messages.maxTitle',
        defaultMessage: 'Длина названия не может быть больше {num} символов.'
      }, { num })
    },
    string: {
      min: (num) => intl.formatMessage({
        id: 'schemes.messages.minString',
        defaultMessage: 'Убедитесь, что это значение содержит не менее {num} символов.'
      }, { num }),
      max: (num) => intl.formatMessage({
        id: 'schemes.messages.maxString',
        defaultMessage: 'Убедитесь, что это значение содержит не более {num} символов.'
      }, { num })
    },
    number: {
      min: (num) => intl.formatMessage({
        id: 'schemes.messages.minNumber',
        defaultMessage: 'Значение должно быть больше или равно {num}'
      }, { num }),
      max: (num) => intl.formatMessage({
        id: 'schemes.messages.maxNumber',
        defaultMessage: 'Значение должно быть меньше или равно {num}'
      }, { num })
    },
    phone: {
      min: (num) => intl.formatMessage({
        id: 'schemes.messages.minPhone',
        defaultMessage: 'Убедитесь, что номер телефона содержит не менее {num} цифр.'
      }, { num }),
      max: (num) => intl.formatMessage({
        id: 'schemes.messages.maxPhone',
        defaultMessage: 'Убедитесь, что номер телефона содержит не более {num} цифр.'
      }, { num })
    }
  }
  return text[value][property](num)
}
