import * as yup from 'yup'
import { ERROR_NUMBERS, INTL_ERROR_MESSAGE } from '../../const'
import { getValidateText } from '../ValidateText'

const CargoSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .max(50, getValidateText('title', 'max', 50))
    .min(
      ERROR_NUMBERS.MIN_STRING_NUMBER,
      getValidateText('title', 'min', ERROR_NUMBERS.MIN_STRING_NUMBER)
    )
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  article: yup.string().trim().nullable(),
  length: yup
    .number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(
      ERROR_NUMBERS.CARGO_MIN_NUMBER,
      getValidateText('number', 'min', ERROR_NUMBERS.CARGO_MIN_NUMBER)
    )
    .max(
      ERROR_NUMBERS.MAX_NUMBER,
      getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER)
    )
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  width: yup
    .number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(
      ERROR_NUMBERS.CARGO_MIN_NUMBER,
      getValidateText('number', 'min', ERROR_NUMBERS.CARGO_MIN_NUMBER)
    )
    .max(
      ERROR_NUMBERS.MAX_NUMBER,
      getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER)
    )
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  height: yup
    .number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(
      ERROR_NUMBERS.CARGO_MIN_NUMBER,
      getValidateText('number', 'min', ERROR_NUMBERS.CARGO_MIN_NUMBER)
    )
    .max(
      ERROR_NUMBERS.MAX_NUMBER,
      getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER)
    )
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  mass: yup
    .number()
    .typeError(INTL_ERROR_MESSAGE.NUMBER_TYPE)
    .min(0.001, getValidateText('number', 'min', 0.001))
    .max(
      ERROR_NUMBERS.MAX_WEIGHT_NUMBER,
      getValidateText('number', 'max', ERROR_NUMBERS.MAX_WEIGHT_NUMBER)
    )
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  stacking_limit: yup
    .number()
    .min(0, getValidateText('number', 'min', 0))
    .max(
      ERROR_NUMBERS.MAX_WEIGHT_NUMBER,
      getValidateText('number', 'max', ERROR_NUMBERS.MAX_WEIGHT_NUMBER)
    )
})
export default CargoSchema
