import { Api } from '../../index.js'
import { runtimeConfig } from '../../../config'


class UserApiService extends Api {


    checkUserExist = async (email) => {
        const res = await this.get(`/user/check_user_exist/`, { email })
        return res.data
    }
    setPassword = async (params) => {
        const res = await this.post('/user/set_password/', params);
        return res.data;
    };

    recoverySendCode = async (params) => {
        const res = await this.post('/user/reset_password/send_code/', params);
        return res.data;
    };

    recoveryCheckCode = async (params) => {
        const res = await this.post('/user/reset_password/check_code/', params);
        return res.data;
    };

    recoverySetPassword = async (params) => {
        const res = await this.post('/user/reset_password/set_password/', params);
        return res.data;
    };

    confirmEmail = async (params) => {
        const res = await this.post('/user/email_confirmation/send_code/', params);
        return res.data;
    };

    confirmCode = async (params) => {
        const res = await this.post('/user/email_confirmation/check_code/', params);
        return res.data;
    };

    createUser = async (params) => {
        const res = await this.post('/user/', params);
        return res.data;
    };

    updateUser = async (id, params) => {
        const res = await this.put(`/user/${id}/`, params);
        return res.data;
    };

    getCurrentUser = async () => {
        const res = await this.get('/user/current/');
        return res.data;
    };
    setUserPhoto = async ({ file }) => {
        const formData = new window.FormData()
        formData.append('avatar', file)
        const res = await this.post('/user/upload_avatar/', formData)
        return res.data
    }

    getUser = async (id) => {
        const res = await this.get(`/user/${id}/`);
        return res.data;
    };

    updatePersonalData = async (id) => {
        const res = await this.patch(`/user/${id}/update_personal_data/`)
        return res.data
}
}

export default new UserApiService(runtimeConfig.API_URL)
