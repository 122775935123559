export const COMPANY_QUERY = {
  STAFF_LIST: 'staffList',
  INVITATION_LIST: 'invitationList',
  CANCELED_LIST: 'canceledList',
  COMPANY_TAB_COUNT: 'companyTabCount'
}

export const COMPANY_ROLE_TYPE = {
  owner: 'owner',
  admin: 'admin',
  user: 'employee'
}

export const INVITATION_STATUS = {
  CREATED: 'created',
  ACCEPTED: 'accepted',
  DECLINED: 'declined'
}

export const COMPANY_TAB = {
  STAFF: 'staff',
  INVITATIONS: 'invitations',
  CANCELED: 'canceled'
}
