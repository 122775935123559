import Media from 'react-media'
import { BaseSelect } from '../../views/Select/BaseSelect'

const SelectOwner = (props) => {
  const { value, setValue, isLoading, loadOptions, borderElementId, companyMembers = 1 } = props

  const avatar = (src) => {
    return (
      src && {
        alignItems: 'center',
        display: 'flex',
        ':before': {
          backgroundImage: `url(${src})`,
          borderRadius: '50%',
          backgroundSize: 'contain',
          content: '" "',
          display: 'block',
          marginRight: 10,
          height: 24,
          width: 24,
          minWidth: 24
        }
      }
    )
  }

  const option = (base, data) => {
    let borderBottom = 'none'
    if (data.data.id === borderElementId) { borderBottom = '1px solid var(--gx-color-line-light-gray)' }
    return {
      ...base,
      ...avatar(data.data?.img),
      borderBottom,
      backgroundColor:
        data.selectProps.value?.id === data.data.id
          ? base.backgroundColor
          : 'var(--gx-color-white)',
      color: '#454e54',
      ':hover': {
        backgroundColor: 'var(--gx-color-list-hover)'
      }
    }
  }

  const stylesSmall = {
    menuList: (base) => {
      return {
        ...base,
        // height: '140px',
        maxHeight: '400px',
        hight: 'max-content',
        minWidth: '200px',
        '::-webkit-scrollbar': {
          width: '4px',
          height: '0px'
        },
        '::-webkit-scrollbar-track': {
          background: '#f1f1f1'
        },
        '::-webkit-scrollbar-thumb': {
          background: 'var(--gx-color-input-hover)',
          borderRadius: '100px'
        }
      }
    },
    option
  }

  const styles = {
    menu: (base) => {
      return {
        ...base,
        hight: 'max-content',
        minWidth: '200px'
      }
    },
    menuList: (base) => {
      return {
        ...base,
        '::-webkit-scrollbar': {
          width: '4px',
          height: '0px'
        },
        '::-webkit-scrollbar-track': {
          background: '#f1f1f1'
        },
        '::-webkit-scrollbar-thumb': {
          background: 'var(--gx-color-input-hover)',
          borderRadius: '100px'
        }
      }
    },
    loadingIndicator: (base) => {
      return {
        ...base,
        position: 'absolute',
        left: '0',
        zIndex: 1,
        width: '100%',
        background: '#FFF ',
        height: '100%',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: '20px'
      }
    },
    option
  }

  return (
    <Media queries={{ small: '(max-width: 568px)' }}>
      {(matches) => (
        <BaseSelect
          value={value}
          isDisabled={companyMembers <= 1}
          name='user'
          theme='select_owner'
          additional={{ page: 1 }}
          isLoading={isLoading}
          loadOptions={loadOptions}
          onChange={setValue}
          styles={matches.small ? stylesSmall : styles}
          filterOption={(el) => el.data.id !== value.id}
        />
      )}
    </Media>
  )
}

export default SelectOwner
