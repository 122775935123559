import React from 'react';
import {runtimeConfig} from '../config';
import BaseApi from '@garpix/base-api';
import Cookies from 'universal-cookie';
import {setCookie} from '../utils';
import { LOCALE } from '../const'

const cookies = new Cookies();

export class Api extends BaseApi {
  AUTH_TOKEN_KEY = null;
  REFRESH_TOKEN_KEY = null;
  
  constructor(url) {
    super(url)
    this.AUTH_TOKEN_KEY = 'auth_token_key'
    this.REFRESH_TOKEN_KEY = 'refresh'
  }

  getLanguage = () => runtimeConfig.RAZZLE_APP_LOCALE || LOCALE.EN;

  getAuthToken = () => cookies.get(this.AUTH_TOKEN_KEY);

  setAuthToken = (token) => setCookie(this.AUTH_TOKEN_KEY, token, 150);

  removeAuthToken = () => cookies.remove(this.AUTH_TOKEN_KEY);

  setRefreshToken = (token) => cookies.set(this.REFRESH_TOKEN_KEY, token);

  getRefreshToken = () => cookies.get(this.REFRESH_TOKEN_KEY)

  axiosOverride = (axios) => {
    const token = this.getAuthToken();
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
    if('Authorization' in axios.defaults.headers.common && !token){
      delete  axios.defaults.headers.common.Authorization
    }
    axios.defaults.headers.common['Accept-Language'] = this.getLanguage();
    return axios;
  };

  getLanding = async (params) => {
    const res = await this.get('/landing/');
    return res.data;
  };

  getCountry = async () => {
    const res = await this.get('/country/');
    return res.data;
  };
}

const API_URL = runtimeConfig.API_URL;

const api = new Api(`${String(API_URL)}`);
export default api;
