import React, { useState } from 'react'
import { useStoreon } from 'storeon/react'
import { useFormik } from 'formik'
import { useModalIntlContent, useModalStore } from '../../hooks/Modals'

import mailingApiService from '../../api/services/mailing'
import { userApiService } from '../../api/services'
import { convertError } from '../../utils'
import { SettingSchema } from '../../utils/ValidateSchemes'

import ProfileViews from '../../views/ProfileViews'

/**
 *
 * @param {boolean} showProfileInfo - флаг, для смены экрана на адаптиве
 * @func handelChangeShowBlocks - хендлер, меняющий флаг для адаптива
 */

const ProfileInfo = ({ showProfileInfo, handelChangeShowBlocks }) => {
  const { dispatch, currentUser } = useStoreon('currentUser')
  const { openModal, contentTypes } = useModalStore()
  const [hasSubscribe, setHasSubscribe] = useState(currentUser?.confirm)
  const modalWarningContent = useModalIntlContent(contentTypes.warning)

  const handleOpenPasswordForm = () => {
    openModal('changePassword')
  }

  /**
   * * метод для подписки/отписки на рассылку не важных писем
   *
   * @param {ChangeEvent<HTMLGxSwitchElement>} e
   */

  const subscribeToMailing = async (e) => {
    try {
      const confirm = e.target.checked
      await mailingApiService.subscribe({ confirm })
      setHasSubscribe(confirm)
    } catch (e) {
      const error = convertError(e)
      openModal('commonConfirm', { ...modalWarningContent, description: error })
    }
  }

  /**
   * * метод для изменения данных о пользователе
   * * указаны изменяемые данные в форме
   * @param {Object} values - данные польователя
   * @param {string} values.email
   * @param {string} values.firstName
   * @param {string} values.lastName
   * @param {string} values.phone
   *
   * @param actions методы формика
   */
  const handleSubmit = (values, actions) => {
    userApiService
      .updateUser(currentUser.id, values)
      .then((newUserData) => {
        dispatch('user/save', { ...currentUser, ...newUserData })
        actions.resetForm({
          values: newUserData
        })
      })
      .catch((e) => {
        if (e.response) {
          const errors = e.response.data
          errors && actions.setErrors(errors)
        } else {
          const error = convertError(e)
          openModal('commonConfirm', { ...modalWarningContent, description: error })
        }
      })
  }

  const handleChangePhone = (e) => {
    const phone = e.detail.value
    tools.setFieldValue('phone', phone)
  }

  /**
   * * Форма, для изменения персональных данных юзера
   */
  const tools = useFormik({
    initialValues: currentUser,
    validationSchema: SettingSchema,
    onSubmit: handleSubmit
  })

  return (
    <ProfileViews.ProfileInfo
      showProfileInfo={showProfileInfo}
      tools={tools}
      hasSubscribe={hasSubscribe}
      subscribeToMailing={subscribeToMailing}
      handelChangeShowBlocks={handelChangeShowBlocks}
      handleOpenPasswordForm={handleOpenPasswordForm}
      handleChangePhone={handleChangePhone}
    />
  )
}

export default ProfileInfo
