import React from 'react'
import ModalView from '../../../ModalView'
import styles from '../styles/index.module.scss'
import { BUTTON_THEME } from '../../../../const'
import Checkbox from '../../../Checkbox'
import ModalSelectComponent from '../../../../components/ModalSelectComponent'
import { FormattedMessage, useIntl } from 'react-intl'

const DeleteStaff = (props) => {
  const {
    handleDelete,
    handleClose,
    moveSpaces,
    moveCargoes,
    setMoveSpaces,
    setMoveCargoes,
    selectProps,
    user
  } = props
  const intl = useIntl()
  return (
    <div>
      <ModalView.Icon.Remove />
      <ModalView.Title>
        <FormattedMessage
          id='modals.deleteStaff.title'
          defaultMessage='Вы уверены что хотите удалить сотрудника <span>{email}</span> из компании?'
          values={{
            email: user.email,
            span: chunks => <span className={styles['text--underline']}>{chunks}</span>
          }}
        />
      </ModalView.Title>
      <div className={styles.delete_staff__checkboxes}>
        <Checkbox
          checked={moveSpaces} onGx-change={e => setMoveSpaces(e.target.checked)}
        >
          <FormattedMessage
            id='modals.deleteStaff.projectCheckbox'
            defaultMessage='Передать проекты и пространства сотрудника'
          />
        </Checkbox>
        <Checkbox checked={moveCargoes} onGx-change={e => setMoveCargoes(e.target.checked)}>
          <FormattedMessage
            id='modals.deleteStaff.cargoesCheckbox'
            defaultMessage='Передать грузы сотрудника'
          />
        </Checkbox>
      </div>
      {
        (moveSpaces || moveCargoes) && (
          <ModalSelectComponent
            {...selectProps}
            label={intl.formatMessage({
              id: 'modals.deleteStaff.selectLabel',
              defaultMessage: 'Новый владелец'
            })}
          />
        )
      }
      {
        (!moveSpaces || !moveCargoes) &&
          (
            <div className={styles.delete_staff__warning}>
              <FormattedMessage
                id='modals.deleteStaff.warningMessage'
                defaultMessage='Не переданные данные будут удалены'
              />
            </div>
          )
      }
      <ModalView.Buttons>
        <ModalView.Buttons.Button onClick={handleClose} theme={BUTTON_THEME.FILL_ICON.SMALL}>
          <FormattedMessage
            id='cancel'
            defaultMessage='Отмена'
          />
        </ModalView.Buttons.Button>
        <ModalView.Buttons.Button
          onClick={handleDelete}
          theme={BUTTON_THEME.DANGER_ICON.SMALL}
        >
          <FormattedMessage
            id='delete'
            defaultMessage='Удалить'
          />
        </ModalView.Buttons.Button>
      </ModalView.Buttons>
    </div>
  )
}

export default DeleteStaff
