import React from 'react'
import { Helmet } from 'react-helmet'
import usePage from '../../hooks/usePage'

const Seo = ({ children }) => {
  const { page } = usePage()
  const { object } = page.init_state
  const { seo_title: seoTitle = '', title } = object
  const currentTitle = seoTitle === '' ? title : seoTitle
  return (
    <>
      <Helmet>
        <title>{currentTitle ?? ''}</title>
      </Helmet>
      <>
        {children}
      </>
    </>
  )
}

export default Seo
