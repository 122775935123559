import { persistState } from '@storeon/localstorage'
import { storeonDevtools } from 'storeon/devtools'
import {
  countriesModule,
  modalModule,
  projectFetchingStatusModule,
  projectModule,
  projectNotificationModule,
  sliceBoxModule,
  tariffsModule,
  userModule,
  bitrixUtmModule,
  settingsSceneModule,
  socketModule,
  btnInfoModule,
  localeModule,
  singleTooltip
} from "./StoreModules";

export const storeonParams = [
  localeModule,
  projectFetchingStatusModule,
  projectNotificationModule,
  projectModule,
  userModule,
  countriesModule,
  sliceBoxModule,
  tariffsModule,
  modalModule,
  bitrixUtmModule,
  settingsSceneModule,
  socketModule,
  btnInfoModule,
  persistState(['settings_scene']),
  singleTooltip
]

if (process.env.NODE_ENV === 'development') {
  storeonParams.push(storeonDevtools)
}
