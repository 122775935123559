import {Api} from '../../index.js'
import { runtimeConfig } from '../../../config'


class ProjectApiService extends Api {
    projectTariff = async () => {
        const res = await this.get('/project/check_tariff/');
        return res.data;
    };
    getProjects = async ({ page, title__icontains, ordering, page_size = 10, owner_id }) => {
        const res = await this.get('/project/', {
            page,
            title__icontains,
            ordering,
            owner_id,
            page_size,
        });
        return res.data;
    };
    copyProject = async ({ id }) => {
        const res = await this.post(`/project/${id}/copy/`);
        return res.data;
    };
    deleteProject = async ({ id }) => {
        const res = await this.delete(`/project/${id}/`);
        return res.data;
    };
    deleteProjectList = async (id) => {
        const res = await this.delete(`/project/list_project_delete/?${id}`);
        return res.data;
    };
    getProject = async ({ id, params }) => {
        const res = await this.get(`/project/${id}/`, params);
        return res.data;
    };
    loadProjectExample = async () => {
        const res = await this.get('/project/sample/');
        return res.data.project;
    };
    createProjectFromFile = async ({ file }) => {
        const fd = new FormData();
        fd.append('file', file);
        const res = await this.post('/project/create_from_file_upload/', fd);
        return res.data;
    };

    createProjectFromFiles = async ({ files }) => {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append( 'files',files[i], files[i].name)
        }
        const res = await this.post('/project/create_files_upload/', formData);
        return res.data;
    };
    createProject = async ({ title }) => {
        const res = await this.post('/project/', {
            title,
            json_request: '',
            json_response: '',
        });
        return res.data;
    };
    updateProject = async ({ id, title }) => {
        const res = await this.patch(`/project/${id}/`, {
            title,
        });
        return res.data;
    };

    getGroups = async ({ id, params }) => {
        const res = await this.get(`/project/${id}/groups/`, params)
        return res.data
    }
}

export default new ProjectApiService(runtimeConfig.API_URL)
