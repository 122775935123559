export const INPUT_REGEXP = {
  PROFILE_NAME: /[~.!?@#$%^&*_+{}\\/:[\];`<>,|"'()\d]/g,
  PROFILE_COMPANY: /[~.!?@#$%^&*_+{}\\/:[\];`<>,|]/g
}

export const APP_ENVIRONMENT = {
  DEVELOPMENT: 'test',
  PRODUCTION: 'prod'
}

export const INPUT_THEME = {
  DEFAULT: 'input_default'
}

export const ANIMATION_TIME = {
  ACCORDION: 300
}

export const EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

export const ICON_VARIANT = {
  DANGER: 'danger',
  GRAY: 'gray',
  BLUE: 'blue'
}

export const MEDIA_QUERIES = {
  mobile: '(max-width: 568px)',
  tablet: '(max-width: 1024px)'
}

export const LOCALE = {
  RU: 'ru-RU',
  EN: 'en-US'
}

export const ICON_SIZE = {
  LG: {
    w: 24,
    h: 24
  }
}

export const INTL_MESURES = {
  mm: {
    id: 'mm',
    defaultMessage: 'мм'
  },
  m: {
    id: 'm',
    defaultMessage: 'м'
  },
  kg: {
    id: 'kg',
    defaultMessage: 'кг'
  },
  sec: {
    id: 'sec',
    defaultMessage: 'сек'
  },
  pcs: {
    id: 'pcs',
    defaultMessage: 'шт'
  }
}

export const PAGE_MODELS = {
  STATISTIC: 'StatisticPage',
  NEWS: 'NewsPage',
  NEWS_LIST: 'NewsListPage',
  NOTIFICATIONS: 'NotificationsListPage',
  LANDING: 'LandingPage',
  API: 'ApiDocumentationPage',
  PROFILE: 'ProfilePage',
  TARIFFS: 'TariffsPage',
  HELP: 'HelpPage',
  PROMOCODES: 'PromoCodesPage',
  REGISTRATION: 'RegistrationPage',
  AUTH: 'LoginPage',
  RECOVERY: 'PasswordRecoveryPage',
  RESTORE: 'RESTORE_PASSWORD_SET_PASSWORD',
  INFO: 'InfoPage',
  CARGOS: 'CargosListPage',
  CREATE_CARGO: 'CreateCargoPage',
  UPDATE_CARGO: 'UpdateCargoPage',
  SPACES: 'CargoSpacesListPage',
  CREATE_SPACE: 'CreateSpacesListPage',
  UPDATE_SPACE: 'UpdateCargoSpacePage',
  FAIL_PAY: 'FAIL',
  PROJECT: 'Project',
  PROJECT_REPORT: 'ProjectReportPage',
  PROJECTS_LIST: 'ProjectsListPage',
  404: 'Page404',
  FAQ: 'FaqPage',
  PAYMENT: 'PaymentPage',
  SUCCESS: 'SUCCESS',
  VISUAL: 'VisualPage',
  PAYMENT_RESULT: 'PaymentResultPage',
  MAILING: 'MailingPage'
}
export const MODAL_TYPES = {
  USER_IN_COMPANY: 'userInCompany',
  CHANGE_COMPANY_OWNER: 'changeCompanyOwner',
  CHANGE_TARIFF: 'changeTariff',
  DELETE_STAFF: 'deleteStaff',
  ADD_STAFF: 'addStaff',
  RENAME: 'rename',
  HOME_VIDEO: 'homeVideo',
  CHANGE_PASSWORD: 'changePassword',
  HEADER_MENU: 'headerMenu',
  REMOVE: 'remove',
  SEND_REQUEST: 'sendRequest',
  COMMON_CONFIRM: 'commonConfirm',
  SAVE_CARGO_SPACE: 'saveCargoSpace',
  SAVE_CARGO: 'saveCargo',
  RENAME_PAGE_ITEM: 'renamePageItem',
  REMOVE_PAGE_ITEM: 'removePageItem',
  COPY_PAGE_ITEM: 'copyPageItem',
  TARIFF_LIMIT: 'tariffLimit',
  CREATE_PROJECT: 'createProject',
  MOVE_CARGO: 'moveCargo',
  CONFIRM: 'confirm',
  PROMO_CASH: 'promoCash',
  PROMOCODE_INFO: 'promocodeInfo',
  QUIT_ACCOUNT: 'quitAccount',
  GRADE: 'grade',
  EXPIRATION_TARIFF: 'expirationTariff',
  CHANGE_USER_AVATAR: 'changeUserAvatar',
  UPLOAD_FILE: 'uploadFile',
  SHOW_CARGO_SPACE_VIEW: 'showCargoSpaceView',
  UPLOAD_EXEL: 'uploadExel',
  EMPTY: 'empty'
}
export const CHANGE_TARIFF_MODAL_TYPE = {
  EQUAL: 'equal',
  UPGRADE: 'upgrade',
  DOWNGRADE: 'downgrade'
}

export const CHANGE_TARIFF_MODAL_VARIANT = {
  LANDING: 'landing',
  PROFILE: 'profile'
}

export const DefaultProject = {
  packer: null,
  roadMaps: [],
  cargoSpaces: [],
  cargoList: [],
  calculation: null,
  calculation_status: null,
  is_calculating: false,
  calculation_time: 'medium',
  history: [],
  excel: {},
  demo: false
}

export const COLOR_SCENE = {
  SKY: '#fefefe',
  GROUND: '#5782d2'
}

export const COLOR_USER_AVATAR = {
  BC_COLOR_IMAGE: 'transparent',
  COLOR_CIRCLE_STROKES: [255, 255, 255, 0.6]
}

export const MAX_SIZE_NOTIFICATION_MENU = 4

export const SOCKET_EVENTS = {
  CALCULATION_CALCULATING: 2003,
  CALCULATION_START: 2003,
  CALCULATION_SUCCESS: 2004,
  NOTIFY: 'send_notify',
  LOAD_ERROR: 2008,
  LOAD_CARGO_SPACE: 2006,
  LOAD_CARGOES: 2005,
  LOAD_PROJECT_EXCEL: 2007,
  DELETE_FROM_COMPANY: 2053,
  REPORT_GENERATING: 2013, // палета-пространство  начало генерации отчета
  FULL_REPORT_GENERATING: 2012, // желтая кнопка начало генерации отчета
  PIVOT_REPORT_GENERATING: 2014, // синяя кнопка начало генерации отчета
  FULL_REPORT_SUCCESS: 2010, // желтая кнопка завершение генерации отчета
  PIVOT_REPORT_SUCCESS: 2011, // синяя кнопка завершение генерации отчета
  REPORT_SUCCESS: 2009, // палета-пространство завершение генерации отчета
  EXPIRATION_TARIFF: 2030, // окончание подписки тарифа
  STATISTICS: 2026, // уведомление обновление статистики
  CHANGED_PROJECT: 2038 // проект компании был изменен
}

export const EXCEL_EVENTS = [
  SOCKET_EVENTS.LOAD_CARGO_SPACE,
  SOCKET_EVENTS.LOAD_CARGOES
]

export const REPORT_STATUS = {
  COMPLETED: 'completed',
  GENERATING: 'generating',
  CREATED: 'created',
  GENERATING_UID: 'generating_uid',
  ERROR_COMPLETED: 'error_completed'
}

export const REPORT_TYPES = {
  EXCLUDING: 'excluding',
  FULL: 'full_pivot'
}

export const REPORT_FORMATS = {
  PDF: 'pdf',
  EXCEL: 'excel'
}
export const REPORT_FORMATS_TITLE = {
  [REPORT_FORMATS.PDF]: 'PDF',
  [REPORT_FORMATS.EXCEL]: 'Excel'
}

export const INTL_ERROR_NOTIFICATIONS = {
  save: {
    id: 'project.notification.save',
    defaultMessage: 'Произошла ошибка. Данные не были сохранены.'
  },
  calc: {
    id: 'project.notification.calc',
    defaultMessage: 'Произошла ошибка. Расчет не выполнен.'
  },
  error: {
    id: 'errorOccurred',
    defaultMessage: 'Произошла ошибка'
  }
}
export const QUERY_KEYS = {
  cargoSpace: ['cargo_space'],
  cargo: 'cargo',
  userCompany: 'getCompany',
  groups: 'groups',
  packer: 'packer'
}

export const CALCULATION_STATUS = {
  CANCEL: 'cancel',
  CALCULATING: 'calculating',
  SUCCESS: 'success',
  ERROR: 'error',
  DESIGNED: 'designed',
  IN_QUEUE: 'in_queue',
  FOR_RECALCULATION: 'for_recalculation',
  QUEUE_RECALCULATION: 'queue_recalculation',
  RECALCULATION: 'recalculation',
  IRRELEVANT: 'irrelevant'
}

export const CALCULATION_STATUS_LABEL = {
  [CALCULATION_STATUS.CANCEL]: 'Расчёт отменен',
  [CALCULATION_STATUS.CALCULATING]: 'Расчёт',
  [CALCULATION_STATUS.SUCCESS]: 'Расчёт выполнен',
  [CALCULATION_STATUS.ERROR]: 'Ошибка расчёта',
  [CALCULATION_STATUS.DESIGNED]: 'Спроектирован',
  [CALCULATION_STATUS.IN_QUEUE]: 'В очереди',
  [CALCULATION_STATUS.FOR_RECALCULATION]: 'Для рекалькуляции',
  [CALCULATION_STATUS.QUEUE_RECALCULATION]: 'В очереди на рекалькуляцию',
  [CALCULATION_STATUS.RECALCULATION]: 'Рекалькуляция',
  [CALCULATION_STATUS.IRRELEVANT]: 'Неактуальный'
}

export const INTL_CALCULATION_STATUS_LABEL = {
  [CALCULATION_STATUS.CANCEL]: {
    id: 'calculationCanceled',
    defaultMessage: 'Расчёт отменен'
  },
  [CALCULATION_STATUS.CALCULATING]: {
    id: 'calculation',
    defaultMessage: 'Расчёт'
  },
  [CALCULATION_STATUS.SUCCESS]: {
    id: 'calculationDone',
    defaultMessage: 'Расчёт выполнен'
  },
  [CALCULATION_STATUS.ERROR]: {
    id: 'calculationError',
    defaultMessage: 'Ошибка расчёта'
  },
  [CALCULATION_STATUS.DESIGNED]: {
    id: 'designed',
    defaultMessage: 'Спроектирован'
  },
  [CALCULATION_STATUS.IN_QUEUE]: {
    id: 'queue',
    defaultMessage: 'В очереди'
  },
  [CALCULATION_STATUS.FOR_RECALCULATION]: {
    id: 'forRecalculation',
    defaultMessage: 'На перерасчет'
  },
  [CALCULATION_STATUS.QUEUE_RECALCULATION]: {
    id: 'queueRecalculation',
    defaultMessage: 'В очереди на перерасчет'
  },
  [CALCULATION_STATUS.RECALCULATION]: {
    id: 'recalculation',
    defaultMessage: 'Перерасчет'
  },
  [CALCULATION_STATUS.IRRELEVANT]: {
    id: 'irrelevant',
    defaultMessage: 'Неактуальный'
  }
}

export const SPACES_TYPES = [
  {
    id: 0,
    label: 'Фургон',
    value: 'van'
  },
  {
    id: 1,
    label: 'Фура',
    value: 'truck'
  },
  {
    id: 2,
    label: 'Контейнер',
    value: 'container'
  },
  {
    id: 3,
    label: 'Палета',
    value: 'pallet'
  }
]

export const INTL_SPACE_TYPES = [
  {
    id: 0,
    label: {
      id: 'entities.van',
      defaultMessage: 'Фургон'
    },
    value: 'van'
  },
  {
    id: 1,
    label: {
      id: 'entities.truck',
      defaultMessage: 'Фура'
    },
    value: 'truck'
  },
  {
    id: 2,
    label: {
      id: 'entities.container',
      defaultMessage: 'Контейнер'
    },
    value: 'container'
  },
  {
    id: 3,
    label: {
      id: 'entities.pallet',
      defaultMessage: 'Палета'
    },
    value: 'pallet'
  }
]

export const INTL_AXLES_TITLE = {
  id: 'space.field.axle',
  defaultMessage: 'Ось {number}'
}

export const PERMITTED_JIVO_PAGE = [
  'LandingPage',
  'PrivacyPolicyPage',
  'ContractOfferPage'
]

export const FB_APP_ID = '196504721678157'
export const GOOGLE_APP_ID =
  '503759428682-39u494ue88eo7p4dn6it9kopiq6850mk.apps.googleusercontent.com'
export const SOCIAL_CLIENT_ID = {
  facebook: 'fZslwWP2xPPmpMtW0vYbEN09vaA8LqN5dxuvY1Mu',
  google: 'OqEvkDkjRDhYA4A7woTBvPavLF70AYbjvaOIfTIn'
}
export const SOCIAL_CLIENT_SECRET = {
  facebook:
    'oiNHqXw35G9qitndHjsQNOzOcgX2fUx9e7Nt3ZQ6u8FPOo8u9ZUe5GCp7AnXQjDzaRreNsu0WDf94bUcLrN5Rv5S1RvjbogGLeOvcBwGicbfVFE1iCLWHjKGONL4SanW',
  google:
    'R7wKxsT6QOEF6TCZhB8XxKx3vYXgf00gOg6m4MVldgA3Q14MpC1ANjfIm6WeY51KHq4KWTETg9rYFUhjlVh4kObHebltkVblkkAxzjkD3BXV9ZPb48JNxeYsa7oVFg07'
}

export const COLORS_FOR_DROPDOWN = [
  '#633517',
  '#401E07',
  '#B1734B',
  '#a6001a',
  '#920048',
  '#D3354E',
  '#e06000',
  '#ee9600',
  '#ffab00',
  '#007EFF',
  '#004d34',
  '#003222',
  '#00590F',
  '#009e21',
  '#00477e',
  '#00a9d2',
  '#006679',
  '#0E0058',
  '#8000ff',
  '#5400A6',
  '#A140FF',
  '#ff0080',
  '#BF3079',
  '#FF40A1',
  '#cacaca',
  '#1A2A3A'
]

// Current colors from redesign
// "#FF0000",
// "#A6001A",
// "#920048",
// "#D3354E",
// "#FF6600",
// "#FF9900",
// "#FFCC00",
// "#FFFF00",
// "#633517",
// "#401E07",
// "#99CC00",

export const METER_RATIO = 1000

export const SEO_TITLE = {
  NEWS: 'Новости',
  NEWS_LIST: 'Блог',
  AUTH: 'Авторизация',
  HELP: 'Помощь',
  REGISTRATION: 'Регистрация',
  RESTORE_PASS: 'Восстановление пароля',
  CARGO: 'Грузы',
  CARGO_CREATE: 'Cоздание груза',
  CARGO_SPACE: 'Грузовые пространства',
  CARGO_SPACE_CREATE: 'Создание грузового пространства',
  CARGO_SPACE_UPDATE: 'Изменение грузового пространства',
  CARGO_UPDATE: 'Изменение груза',
  SUCCESS_PAY: 'Успешная оплата',
  FAIL_PAY: 'Неуспешная оплата',
  PROJECTS: 'Проекты',
  PROFILE: 'Профиль',
  PROJECT:
    'Быстрый расчёт правильной  укладки груза в контейнер - Garpix Load System',
  HOME: 'Система умной погрузки грузового пространства - Garpix Load System',
  CONTACT_OFFER: 'Условия использования сайта - Garpix Load System',
  PRIVACY_POLICY: 'Политика конфиденциальности - Garpix Load System',
  GREETING: 'Добро пожаловать в Garpix Load System',
  NOT_FOUND_PAGE: 'Страница не найдена',
  REPORT: 'Скачать отчет',
  VISUAL: 'Визуализация отчета',
  NOTIFICATIONS: 'Уведомления',
  API_DOCUMENTATION: 'API',
  FAQ: 'FAQ',
  TARIFFS: 'Тарифы',
  PROMOCODES: 'Управление промокодами',
  PAYMENT_RESULT: 'Статус оплаты'
}

export const INTL_TITLE = {
  QUEUE: {
    id: 'queue',
    defaultMessage: 'В очереди'
  },
  CREATE_REPORT: {
    id: 'createReport',
    defaultMessage: 'Создать отчет'
  },
  DOWNLOAD_REPORT: {
    id: 'downloadReport',
    defaultMessage: 'Скачать отчет'
  },
  CARGOES: {
    id: 'cargoes',
    defaultMessage: 'Грузы'
  },
  CS: {
    id: 'CS',
    defaultMessage: 'ГП'
  },
  CARGO_SPACES: {
    id: 'cargoSpaces',
    defaultMessage: 'Грузовые пространства'
  },
  CALCULATION: {
    id: 'calculation',
    defaultMessage: 'Расчёт'
  },
  HISTORY: {
    id: 'history',
    defaultMessage: 'История'
  },
  CALCULATION_HISTORY: {
    id: 'calculationHistory',
    defaultMessage: 'История расчетов'
  },
  KG: {
    id: 'kg',
    defaultMessage: 'кг'
  },
  RESULTS: {
    id: 'results',
    defaultMessage: 'Результаты'
  },
  SAVE: {
    id: 'save',
    defaultMessage: 'Сохранить'
  },
  DOWNLOAD: {
    id: 'download',
    defaultMessage: 'Скачать'
  },
  CREATE: {
    id: 'create',
    defaultMessage: 'Создать'
  },
  CANCEL: {
    id: 'cancel',
    defaultMessage: 'Отмена'
  },
  SEARCH: {
    id: 'searchResultsNotFound',
    defaultMessage: 'Результаты поиска не найдены'
  },
  NO_QUESTION: {
    id: 'noQuestions',
    defaultMessage: 'Нет вопросов'
  }
}
export const INTL_LABELS = {
  van: {
    id: 'entities.van',
    defaultMessage: 'Фургон'
  },
  pallet: {
    id: 'entities.pallet',
    defaultMessage: 'Палета'
  },
  container: {
    id: 'entities.container',
    defaultMessage: 'Контейнер'
  },
  truck: {
    id: 'entities.truck',
    defaultMessage: 'Фура'
  }
}

export const INVITATION_STATUS_LABEL = {
  active: {
    id: 'active',
    defaultMessage: 'Активен'
  },
  notActive: {
    id: 'notActive',
    defaultMessage: 'Не активен'
  },
  canceled: {
    id: 'rejected',
    defaultMessage: 'Отклонено'
  },
  notConfirmed: {
    id: 'notConfirmed',
    defaultMessage: 'Не подтверждён'
  }
}

export const LABELS_PROJECT_SPACE = {
  ...INTL_LABELS,
  truck: {
    id: 'semitrailer',
    defaultMessage: 'Полуприцеп'
  }
}

export const DATA_STATES = {
  LOADED: 'loaded',
  LOADING: 'loading',
  FAIL: 'fail'
}

export const ERROR_NUMBERS = {
  MIN_PHONE_NUM: 11,
  MAX_PHONE_NUM: 14,
  CARGO_MIN_NUMBER: 10,
  MIN_NUMBER: 50,
  MAX_NUMBER: 32776,
  MAX_STRING_NUMBER: 255,
  MAX_STRING_EXTERNAL_UID: 250,
  MAX_PROJECT_TITLE_LENG: 100,
  MIN_STRING_NUMBER: 1,
  CEILING_MAX_NUMBER: 2147483647,
  CEILING_MIN_NUMBER: 0,
  MAX_WEIGHT_NUMBER: 2147483647,
  MIN_WEIGHT_NUMBER: 1,
  MIN_INN_NUMBER: 10,
  MAX_INN_NUMBER: 12,
  MIN_OGRN_NUMBER: 13,
  MAX_ORGN_NUMBER: 15,
  LEN_KPP_NUMBER: 9,
  LEN_SCHET_NUMBER: 20,
  VAN: {
    MIN_DISTANCE_NUMBER: 900 // mm
  },
  TRACTOR: {
    MIN_DISTANCE_NUMBER: 900, // mm
    MIN_HITCH_DISTANCE_NUMBER: 500 // mm
  },
  TRUCK: {
    MIN_HITCH_DISTANCE_NUMBER: 2000 // mm
  }
}

export const INTL_ERROR_MESSAGE = {
  REQUIRED: {
    id: 'requiredField',
    defaultMessage: 'Обязательное поле'
  },
  EMAIL: {
    id: 'errors.email',
    defaultMessage: 'Не является электронной почтой.'
  },
  MAX_LENGTH_MESSAGE: {
    id: 'errors.maxLengthMessage',
    defaultMessage: 'Слишком большое сообщение'
  },
  SHORT_PASSWORD: {
    id: 'errors.shortPassword',
    defaultMessage: 'Введённый пароль слишком короткий. Он должен содержать как минимум 8 символов.'
  },
  ONLY_NUMBER_PASSWORD: {
    id: 'errors.onlyNumberPassword',
    defaultMessage: 'Введённый пароль состоит только из цифр'
  },
  MATCH_PASS: {
    id: 'errors.matchPass',
    defaultMessage: 'Пароли не совпадают.'
  },
  NUMBER_TYPE: {
    id: 'error.numberType',
    defaultMessage: 'Требуется число'
  },
  INTEGER: {
    id: 'errors.integer',
    defaultMessage: 'Требуется целое значение.'
  },
  TYPE_ERROR: {
    id: 'errors.typeError',
    defaultMessage: 'Требуется целочисленное значение.'
  },
  ERROR_FULL_LENGTH: {
    id: 'errors.errorFullLength',
    defaultMessage: 'Значение должно быть больше или равно значению длины'
  },
  WIDTH: {
    id: 'errors.width',
    defaultMessage: 'Ширина не может быть больше длины'
  },
  LOADING_LENGTH: {
    id: 'errors.loadingLength',
    defaultMessage: 'Значение должно быть меньше или равно значению длины'
  },
  LOADING_WIDTH: {
    id: 'errors.loadingWidth',
    defaultMessage: 'Значение должно быть меньше или равно значению ширины'
  },
  LOADING_HEIGHT: {
    id: 'errors.loadingHeight',
    defaultMessage: 'Значение должно быть меньше или равно значению высоты'
  },
  LOADING_LENGTH_UP: {
    id: 'errors.loadingLengthUp',
    defaultMessage: 'Значение должно быть больше или равно значению длины'
  },
  LOADING_WIDTH_UP: {
    id: 'errors.loadingWidthUp',
    defaultMessage: 'Значение должно быть больше или равно значению ширины'
  },
  AXLES_DISTANCE: {
    id: 'errors.axlesDistance',
    defaultMessage: 'Дистанция осей не может быть больше полной длины'
  },
  AXLES_DEFAULT_LOAD: {
    id: 'errors.axlesDefaultLoad',
    defaultMessage: 'Сумма общей нагрузки на оси должна быть равна массе'
  },
  MAX_LOAD: {
    id: 'errors.maxLoad',
    defaultMessage: 'Макс. нагрузка меньше значения в поле Нагрузка на ось (кг)'
  },
  INVALID_NAME: {
    id: 'errors.invalidName',
    defaultMessage: 'Имя может состоять из кириллицы, латиницы и "-".'
  },
  INVALID_FULLNAME: {
    id: 'errors.invalidFullName',
    defaultMessage: 'Фамилия может состоять из кириллицы, латиницы и "-".'
  }
}
export const CARGO_VALUES = {
  overhang_angle: 'overhang_angle',
  cargo_groups: 'cargo_groups',
  cargo: 'cargo',
  open_group: 'open',
  open_cargo: 'open',
  use_pallet: 'use_pallet',
  count: 'count',
  color: 'color',
  pallet: 'pallet'
}

export const ENTITY_TYPES = {
  CARGO_SPACE: 'cargo_space',
  PROJECT: 'project',
  CARGO: 'cargo'
}

export const CONTENT_HINT = {
  DEMO: 'Демо',
  USER: 'Пользовательский',
  COMMON: 'Общий'
}

export const SPACE_TYPES = {
  VAN: 'van',
  TRUCK: 'truck',
  PALLET: 'pallet',
  CONTAINER: 'container',
  TRACTOR: 'tractor'
}

export const SCALE_LOADING_SIZE = {
  [SPACE_TYPES.VAN]: 0.431,
  [SPACE_TYPES.TRUCK]: 0.631,
  [SPACE_TYPES.PALLET]: 0.81,
  [SPACE_TYPES.CONTAINER]: 0
}

// Landing
export const CARDS = {
  LOGO_SLIDER: {
    id: 'logos'
  },
  NEWS: {
    id: 'news',
    title: 'Новости'
  },
  CALL_ORDER: {
    id: 'callOrder'
  },
  INSTRUCTIONS: {
    id: 'instructions',
    title: 'Инструкции'
  },
  SUITABILITY: {
    id: 'solutions',
    title: 'Для кого подходит'
  },
  POSSIBILITIES: {
    id: 'features',
    preTitle: 'Функционально и полезно',
    title: 'Коротко о возможностях сервиса GLS'
  },
  LOAD_PERFOMANCE: {
    id: 'performance',
    preTitle: 'Функционально и полезно',
    title: 'Эффективность загрузки'
  },
  LOAD_PERFOMANCE_NEW: {
    id: 'performanceNew'
  },
  FREQUENT_PROBLEMS: {
    id: 'FrequentProblems',
    preTitle: 'Проблемы и решения',
    title: 'Самые частые проблемы при перевозке грузов'
  },
  PARTNERS: {
    id: 'partners',
    preTitle: 'Наши клиенты',
    title: 'Те, кто уже попробовал и оценил систему GLS'
  },
  WORK_RESULTS: {
    id: 'performance',
    preTitle: 'Кейсы',
    title: 'Результаты работы'
  },
  WORK_RESULTS_SLIDER: {
    id: 'result',
    preTitle: 'Кейсы',
    title: 'Результаты работы'
  },
  HOWS_ITEMS: {
    id: 'hows',
    preTitle: 'Функционально и полезно',
    title: 'Как работать в сервисе GLS'
  },
  TARIFFS: {
    id: 'tariffs',
    preTitle: 'Функционально и полезно',
    title: 'Тарифы на использование системы',
    subTitle: `Для оформления лицензии и приобретения тарифа перейдите в личный кабинет, это возможно сделать
                после регистрации и авторизации. Далее выберите тариф и нажмите "купить"`
  },
  WATCH_US: {
    id: 'watchUs',
    preTitle: 'Функционально и полезно',
    title: 'Смотрите нас на Youtube',
    subTitle: 'Система учитывает все необходимые параметры грузов: вес, хрупкость, возможность кантования, очередность выгрузки и так далее"'
  },
  FAQ: {
    id: 'faq'
  },
  TARIFF_ADVANTAGE: {
    id: 'tariffAdvantage',
    preTittle: 'сравнение',
    title: 'Сравнение тарифов',
    subTitle: 'Для оформления лицензии и приобретения тарифа перейдите в личный кабинет, это возможно сделать после регистрации и авторизации. Далее выберите тариф и нажмите “Купить”.'
  },
  CLIENTS: {
    id: 'clients',
    preTitle: 'Наши клиенты',
    title: 'Те, кто уже попробовал и оценил систему GLS'
  },
  LOGO_GRID: {
    id: 'logoGrid'
  }
}

export const AUTH_TOKEN_KEY = 'auth_token'

export const LIMIT_RESULTS = 100

export const BUTTON_THEME = {
  TAB: 'tab',
  PAGINATION: 'pagination',
  TABLE: {
    SORT: 'table_sort',
    NO_SORT: 'table_no_sort'
  },
  COLOR: {
    SELECT: 'color_select',
    TRIGGER: 'color_trigger'
  },
  SWITCHER: 'switcher',
  TARIFFS_ITEM: 'tariffs_item',
  PLAY: 'play',
  TEXT: 'text',
  REPORT_DOWNLOAD: {
    DEFAULT: 'report_download',
    ALL_ADDITIONAL: 'report_download--all-additional',
    ALL: 'report_download--all',
    REF: 'report_download--ref'
  },
  TEXT_BLACK: 'text--black',
  PROFILE_DROPDOWN: 'profile_dropdown',
  TRANSPARENT_WITH_ICON: {
    DEFAULT: 'transparent_with_icon',
    BLUE: 'transparent_with_icon--blue',
    RED: 'transparent_with_icon--red'
  },
  FILL_ICON: {
    FULL: 'fill_icon--full',
    ORANGE: 'fill_icon--orange',
    LANDING: 'fill_icon--landing',
    SMALL: 'fill_icon--small',
    WHITE: 'fill_icon--white',
    PANEL: 'fill_icon--panel',
    SS: 'fill_icon--ss',
    CUSTOM_FILL: 'fill_icon--custom_fill',
    CUSTOM_STROKE: 'fill_icon--custom_stroke'
  },
  STROKE_ICON: {
    SMALL: 'stroke_icon--small'
  },
  DANGER_ICON: {
    SMALL: 'danger_icon--small'
  },
  PANEL_STATUS: {
    STATUS: 'panel_status',
    RIGHT: 'panel_status--right',
    LEFT: 'panel_status--left',
    STEP: 'panel_status--step'
  },
  TEXT_WITH_ICON: {
    DEFAULT: 'text_with_icon',
    SHORT: 'text_with_icon--short'
  },
  PANEL_ADD: 'panel_add'
}
export const BUTTON_VARIANT = {
  CLOSE: 'close',
  CONTEXT_MENU: 'context-menu__btn',
  TEXT: 'text'
}
export const BUTTON_VARIANT_ICON = {
  MEDIUM: 'medium',
  BIG: 'big'
}
export const BUTTON_SIZE = {
  MEDIUM: 'med'
}

export const TITLE_VARIANT = {
  HOWS_CARD: 'howscard',
  POSSIBILITIES: 'possibilities',
  SUITABILITY: 'suitability',
  LIST_PAGES: 'list_pages',
  CREATE_PAGES: 'create_pages',
  LARGE_MODAL: 'large_modal',
  AXES_BLOCK: {
    DEFAULT: 'axes_block',
    SEMITRAILER: 'axes_block_semitrailer',
    AXIS_BLOCK: 'axis_block'
  },
  PANEL: 'panel',
  ASIDE_REG: 'aside_reg',
  REG: 'reg',
  PAGE_APP: 'page-app',
  FOOTER_H2: 'footer_h2',
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  SUCCESS_PAY: 'success_pay',
  REPORT_PAGE: 'report_page'
}
export const TITLE_TYPE = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6'
}

export const DIVIDER_THEME = {
  M20: 'divider--m20'
}

export const LINK_THEME = {
  FILL_ICON: {
    LANDING: 'fill_icon--landing',
    PANEL: 'fill_icon--panel',
    WHITE: 'fill_icon--white',
    ORANGE: 'fill_icon--orange'
  },
  PROJECT: 'project_icon',
  TRANPARENT: 'transparent'
}
export const LINK_TARGET = {
  BLANK: '_blank'
}

export const LANDING_ID = {
  WELCOME: 'welcome'
}

export const SECTION_VARIANT = {
  LIST_PAGES: 'list_pages',
  REPORT_PAGE: 'report_page',
  PROFILES: 'profiles',
  PERSONAL_AREA_PAGES: 'personal-area'
}

export const TABS_MODEL = {
  PROFILE: 'ProfilePage',
  TARIFFS: 'TariffsPage',
  PROMOCODES: 'PromoCodesPage',
  HELP: 'HelpPage'
}

export const TABLE_FETCH_STATUS = {
  LOADING: 'loading',
  LOADED: 'loaded'
}

export const USER_ROLE = {
  USER: 'user',
  PARTNER: 'partner'
}

export const TYPE_PROMOTION = {
  PERCENT: 'percent',
  PERIOD: 'period'
}

export const FORM_TYPE = {
  request: 'request',
  question: 'question',
  partner: 'partner',
  promo_code: 'promo_code',
  withdrawal_request: 'withdrawal_request'
}

export const ACTION_BUTTON_TYPE = {
  LINK: 'link',
  BUTTON: 'button'
}

export const PROJECT_TAB = {
  CALCULATION: 'calculation',
  SPACES: 'spaces',
  CARGO: 'cargo',
  HISTORY: 'history'
}

export const MAX_GRADE_STARS = 5

export const ACCORDION_TYPE = {
  HELP_PAGE: 'help',
  FAQ_PAGE: 'faq'
}

export const SELECT_TYPE = {
  HELP_PAGE: 'help-page'
}

export const NAME_CONTAINER_SIDE = 'NAME_CONTAINER_SIDE'
export const LINE_CONTAINER = 'LINE_CONTAINER'

export const QUESTION_EMPTY_THEME = {
  FAQ: 'faq',
  HELP: 'help'
}

export const secondTitles = ['секунду', 'секунды', 'секунд']

export const TARIFF_TAB_GROUP_THEME = {
  LANDING: 'tariff_tabs__landing'
}
export const HEADER_LANDING_LINK_THEME = {
  default: 'header-menu__item'
}
export const TARIFF_PANEL_THEME = {
  DEFAULT: 'card_theme'
}

export const TARIFF_TAB_GROUP_TAB = {
  LANDING: 'tariff_tabs__tab_landing'
}
export const USER_AVATAR_CONST = {
  SCALE: 1.1,
  MAX_SCALE: 4,
  MIN_SCALE: 1,
  STEP: 0.1,
  MAX_SIZE_FILE: 20e6 // 20mb (дизайн)
}

export const COMPANY_PAGES = {
  WELCOME: 'welcome',
  EDIT_COMPANY: 'edit',
  STAFF_LIST: 'staffList'
}

export const COMPANY_PROFILE_INFO = {
  owner: 'owner',
  admin: 'Админ',
  user: 'Сотрудник',
  status: {
    active: 'active'
  }
}

export const FREQUENT_PROBLEMS_IMAGE_THEME = {
  GAZPROM: 'frequentProblems__image_gazprom'
}

export const LOADER_THEME = {
  BUTTON: 'in_button'
}

export const DETAILS_TYPE = {
  SUMMARY: {
    CARGOS: 'cargos-count'
  }
}

export const INDENTATION_CARGO_SPACE = {
  indentation_length: '',
  indentation_length_end: '',
  indentation_width: '',
  indentation_width_right: '',
  indentation_height: '',
  indentation_height_top: ''
}

export const DEFAILT_VALUE_CARGO_SPACE_CREATE = {
  [SPACE_TYPES.VAN]: {
    type: SPACE_TYPES.VAN,
    title: '',
    length: '',
    width: '',
    height: '',
    loading_width: '',
    loading_height: '',
    loading_length: '',
    full_length: '',
    carrying_capacity: '',
    mass: '',
    axles: [],
    ...INDENTATION_CARGO_SPACE
  },
  [SPACE_TYPES.CONTAINER]: {
    type: SPACE_TYPES.CONTAINER,
    title: '',
    length: '',
    width: '',
    height: '',
    loading_width: '',
    loading_height: '',
    loading_length: '',
    full_length: '',
    carrying_capacity: '',
    mass: '',
    ...INDENTATION_CARGO_SPACE
  },
  [SPACE_TYPES.TRUCK]: {
    type: SPACE_TYPES.TRUCK,
    title: '',
    length: '',
    width: '',
    height: '',
    loading_width: '',
    loading_height: '',
    loading_length: '',
    carrying_capacity: '',
    mass: '',
    ...INDENTATION_CARGO_SPACE,
    axles: [],
    hitch_load: '',
    hitch_max_load: '',
    hitch_distance: '',
    tractor: {
      title: '',
      length: '',
      hitch_distance: '',
      mass: '',
      axles: []
    }
  },
  [SPACE_TYPES.PALLET]: {
    type: SPACE_TYPES.PALLET,
    title: '',
    length: '',
    width: '',
    height: '',
    loading_width: '',
    loading_height: '',
    loading_length: '',
    carrying_capacity: '',
    mass: '',
    margin_width: '',
    margin_length: '',
    indent_length: '',
    indent_length_end: '',
    indent_width: '',
    indent_width_right: ''
  }
}

export const PAGE_LOADER_THEME = {
  DEFAULT: 'page_loader',
  LIST_PAGES: 'list_loader'
}

export const TYPE_ADDITIONAL_PARAMETERS = {
  SAINT_GOBEN: 'saint_goben',
  NOT_USED: 'not_used'
}
