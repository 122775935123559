const modalModule = (store) => {
    store.on('@init', () => ({
        showModal: null,
        modalProps: {}
    }))
    store.on('modalType/toggle', (_, { modal, modalProps }) => {
        return {
            showModal: modal,
            modalProps
        }
    })
    store.on('modalType/close', () => ({
        showModal: null,
        modalProps: {}
    }))
}

export default modalModule

