import { userApiService } from "../../../api/services";

export const userModule = (store) => {
    store.on('@init', () => ({ currentUser: null, isShowTrial: false, userOrderTariffs: [], withTariff: false }))
    store.on('user/save', ({ currentUser, isShowTrial, userOrderTariffs, withTariff }, user) => ({
        currentUser: { ...currentUser, ...user },
        isShowTrial,
        userOrderTariffs,
        withTariff
    }))
    store.on('user/clean', () => ({ currentUser: null, isShowTrial: false, userOrderTariffs: [], withTariff: false}))
    store.on('user/order_tariffs_save', ({ currentUser, isShowTrial }, order_tariffs, with_tariff) => ({
        currentUser,
        isShowTrial,
        userOrderTariffs: [...order_tariffs],
        withTariff: with_tariff
    }))
    store.on('user/get', async (state = {}, { handleAccess, handleError }) => {
        try {
            const data = await userApiService.getCurrentUser()

            store.dispatch('user/save', {
                ...data,
                status: true
            })
            handleAccess && handleAccess()
        } catch (e) {
            store.dispatch('user/save', { status: false })
            handleError && handleError()
        }
    })
}

export default userModule