import { randomColor } from 'randomcolor'
import { defineMessage, useIntl } from 'react-intl'
import { uuidv4, cloneDeep } from '../../../../utils'
import { INITIAL_CARGO, INITIAL_CARGO_GROUP } from '../../const'

const cargoGroupLabel = defineMessage({
  id: 'cargoGroupNum',
  defaultMessage: 'Группа {num}'
})

const cargoLabel = defineMessage({
  id: 'cargoNum',
  defaultMessage: 'Груз {num}'
})

/**
 * @hook
 * * Хук, возвращающий методы для взаимодействия/создания грузов
 *
 */

const useCargo = () => {
  /* eslint-disable camelcase */

  const intl = useIntl()

  const getCargoTitle = num => intl.formatMessage(cargoLabel, { num })
  const getGroupTitle = num => intl.formatMessage(cargoGroupLabel, { num })

  /**
   * @func createGroup метод создающий новую группу
   * @returns {Object}
   */

  const createGroup = () => {
    // eslint-disable-next-line no-undef
    const group_id = uuidv4()
    return {
      ...cloneDeep(INITIAL_CARGO_GROUP),
      group_id
    }
  }
  /**
   * @func    createCargo метод создающий новый груз
   * @returns {Object}
   */
  const createCargo = () => {
    const cargo_id = uuidv4()
    const color = randomColor({ luminosity: 'bright' })
    return { ...cloneDeep(INITIAL_CARGO), cargo_id, color }
  }
  /**
   * @func    createGroupWithCargo метод создающий новую группу с грузом
   * @param   {number} num         номер группы
   * @returns {Object}
   */
  const createGroupWithCargo = ({ num }) => {
    const newGroup = createGroup()
    const newCargo = createCargo()
    newGroup.title = getGroupTitle(num)
    newGroup.cargoes.push(newCargo)
    return newGroup
  }

  /**
   * @func    groupCloneDeep   метод возвращающий полную копию группы, но с новыми uuid
   * @param   {Object} group   копируемаю группа
   * @returns {Object}
   */

  const groupCloneDeep = (group) => {
    const copyGroup = cloneDeep(group)
    copyGroup.group_id = uuidv4()
    copyGroup.cargoes.forEach(cargo => {
      cargo.cargo_id = uuidv4()
    })
    return copyGroup
  }

  return {
    createGroupWithCargo,
    createCargo,
    createGroup,
    getCargoTitle,
    groupCloneDeep,
    getGroupTitle
  }
}

export default useCargo
