import * as yup from 'yup'
import { INTL_ERROR_MESSAGE } from '../../const'

function checkOnContent (errMes) {
  return this.test('checkOnContent', errMes, function (value) {
    const { path, createError } = this
    if (!isNaN(Number(value))) {
      return createError({ path, message: errMes })
    }
    return true
  })
}

function matchPasses (errMes) {
  return this.test('matchPasses', errMes, function (value) {
    const { path, createError, parent } = this
    if (parent.new_password !== value) {
      return createError({ path, message: errMes })
    }
    return true
  })
}

yup.addMethod(yup.string, 'checkOnContent', checkOnContent)
yup.addMethod(yup.string, 'matchPasses', matchPasses)

const PasswordSchema = yup.object().shape({
  new_password: yup
    .string()
    .trim()
    .min(
      8,
      INTL_ERROR_MESSAGE.SHORT_PASSWORD
    )
    .checkOnContent(INTL_ERROR_MESSAGE.ONLY_NUMBER_PASSWORD)
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  old_password: yup.string().trim().required(INTL_ERROR_MESSAGE.REQUIRED),
  password2: yup.string().trim().matchPasses(INTL_ERROR_MESSAGE.MATCH_PASS)
})

export default PasswordSchema
