import { GxCheckbox } from '@garpix/garpix-web-components-react'
import React from 'react'
import style from './styles/index.module.scss'

const Checkbox = ({
  children,
  theme = 'checkbox_default',
  ...props
}) => {
  return (
    <GxCheckbox className={style[theme]} {...props}>
      {children}
    </GxCheckbox>
  )
}

export default Checkbox
