/* eslint-disable camelcase */
import React from 'react'
import Button from '../Button'
import { BUTTON_THEME, BUTTON_VARIANT } from './../../const'
import style from './styles/index.module.scss'
import classNames from 'classnames'
import usePage from '../../hooks/usePage'
import TariffInfoView from '../TariffInfoView'
import Tooltip from '../Tooltip'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { cargoIcon, spacesIcon, userIcon } from '../../images'
import { useIntl } from 'react-intl'
import useTranslate from '../../hooks/useTranslate'

const Description = ({ isFree, autoPayment, description, descripitonAuto }) => {
  const intl = useIntl()
  let text
  if (isFree) {
    text = intl.formatMessage({
      id: 'freeTariffLimitMessage',
      defaultMessage: 'Функционал ограничен, чтобы получить доступ ко всем возможностям системы купите платную версию'
    })
  }
  if (autoPayment && descripitonAuto) {
    text = descripitonAuto
  }
  if (!autoPayment && !isFree && description) {
    text = description
  }
  return <p className={style.current_tariff__info_text}>{text}</p>
}

const UserCurrentTariff = ({ currentTariff, cancelModal, isProfile, showAdvantages = false, className }) => {
  const { baseSlug } = usePage()
  const { formatInfinity, intl } = useTranslate()
  return (
    <div className={classNames({
      [style.current_tariff]: true,
      [style['current_tariff--profile']]: isProfile,
      [className]: !!className
    })}
    >
      <div className={classNames({
        [style.current_tariff__block]: true,
        [style['current_tariff__block--profile']]: isProfile
      })}
      >
        <TariffInfoView.Title showIcon={!currentTariff.is_free} title={currentTariff.title} />
        <TariffInfoView.Progressbar currentTariff={currentTariff} />
      </div>
      {
        showAdvantages && (
          <ul className={style.advantages__list}>
            <li className={style.advantages__item}>
              <Tooltip
                placement='bottom'
                projectVariant='tooltip_tabs'
                content={intl.formatMessage({
                  id: 'users',
                  defaultMessage: 'Пользователи'
                })}
              >
                <GxIcon src={userIcon} className={style.advantages__icon} />
              </Tooltip>
              <span className={style.advantages__text}>{formatInfinity(currentTariff.count_employees)}</span>
            </li>
            <li className={style.advantages__item}>
              <Tooltip
                placement='bottom'
                projectVariant='tooltip_tabs'
                content={intl.formatMessage({
                  id: 'spaces',
                  defaultMessage: 'Пространства'
                })}
              >
                <GxIcon src={spacesIcon} className={style.advantages__icon} />
              </Tooltip>
              <span className={style.advantages__text}>{formatInfinity(currentTariff.cargo_space_count)}</span>
            </li>
            <li className={style.advantages__item}>
              <Tooltip
                placement='bottom'
                projectVariant='tooltip_tabs'
                content={intl.formatMessage({
                  id: 'cargoes',
                  defaultMessage: 'Грузы'
                })}
              >
                <GxIcon src={cargoIcon} className={style.advantages__icon} />
              </Tooltip>
              <span className={style.advantages__text}>{formatInfinity(currentTariff.cargos_count)}</span>
            </li>
          </ul>
        )
      }
      {(currentTariff?.tariff_description || currentTariff?.tariff_description_auto) &&
        <div className={style.current_tariff__info}>
          <Description
            isFree={currentTariff?.is_free}
            autoPayment={currentTariff?.auto_payment}
            description={currentTariff?.tariff_description}
            descripitonAuto={currentTariff?.tariff_description_auto}
          />
        </div>}
      {
        currentTariff?.is_free && isProfile &&
          <div className={style['current_tariff__link-btn']}>
            <Button href={baseSlug.pageTariffs}>
              {
                intl.formatMessage({
                  id: 'choosePlan',
                  defaultMessage: 'Выбрать тариф'
                })
              }
            </Button>
          </div>
      }
      {
        currentTariff?.auto_payment && cancelModal &&
          <div className={style.current_tariff__cancel}>
            <Button className={style['current_tariff__cancel-btn']} variant={BUTTON_VARIANT.TEXT} theme={BUTTON_THEME.TEXT_WITH_ICON.DEFAULT} onClick={cancelModal}>
              {
                intl.formatMessage({
                  id: 'stopAutoRenewal',
                  defaultMessage: 'Остановить автопродление'
                })
              }
            </Button>
          </div>
      }
    </div>
  )
}

export default UserCurrentTariff
