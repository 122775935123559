/* eslint-disable camelcase */
import React, { memo } from 'react'
import classNames from 'classnames'
import { GxForm, GxIcon } from '@garpix/garpix-web-components-react'
import Title from '../Title'
import Textarea from '../Textarea'
import SelectViews from '../SelectViews'
import Button from '../Button'
import Checkbox from '../Checkbox'
import { helpQuest, supportImage, youtubeImage } from '../../images'
import { SELECT_TYPE, TITLE_VARIANT } from '../../const'
import { formattingPhone } from '../../utils'
import style from './styles/index.module.scss'
import useTranslate from '../../hooks/useTranslate'

const HelpSendQuestion = ({ formikTools, baseSlug, themes, ...props }) => {
  const { handleSubmit, values, handleChange, touched, errors } = formikTools

  const { feedback_phone } = props?.init_state?.global
  const [hrefPhone, formattedPhone] = formattingPhone(feedback_phone)
  const { intl, translate } = useTranslate()

  return (
    <div className={style.help_right}>
      <div className={style.help_right__wrap}>
        <div className={style.help_form__info}>
          <Title
            className={style.help_title}
            type='h2'
            variant={TITLE_VARIANT.LIST_PAGES}
          >
            {
              intl.formatMessage({
                id: 'haveAnyQuestion',
                defaultMessage: 'Остались вопросы?'
              })
            }
          </Title>
          <p className={style.help_description}>
            {
              intl.formatMessage({
                id: 'help.description',
                defaultMessage: 'Задайте вопрос онлайн и мы свяжемся с вами в ближайшее время.'
              })
            }
          </p>
          <img
            className={style.help_img}
            width={270}
            height={340}
            src={helpQuest}
            alt='question'
          />
        </div>
        <GxForm className={style.help_form} onGx-submit={handleSubmit}>
          <div className={style.help_form__input}>
            <SelectViews.Select
              name='theme'
              data-cy='help-select'
              placeholder={intl.formatMessage({
                id: 'chooseTheme',
                defaultMessage: 'Выберите тему'
              })}
              required
              onGx-change={handleChange}
              value={values.theme}
              label={intl.formatMessage({
                id: 'theme',
                defaultMessage: 'Тема'
              })}
              error={touched.theme && !!errors.theme}
              errorText={translate(errors.theme)}
              theme={SELECT_TYPE.HELP_PAGE}
            >
              {themes.length > 0 &&
                themes.map((theme) => {
                  return (
                    <SelectViews.Item
                      projectVariant={SELECT_TYPE.HELP_PAGE}
                      data-cy='help-select_item'
                      key={theme.id}
                      value={theme.id}
                    >
                      {theme.title}
                    </SelectViews.Item>
                  )
                })}
            </SelectViews.Select>
          </div>
          <div className={style.help_form__input}>
            <Textarea
              name='message'
              data-cy='help-textarea'
              onGx-input={handleChange}
              required
              value={values.message}
              label={intl.formatMessage({
                id: 'message',
                defaultMessage: 'Сообщение'
              })}
              error={touched.message && !!errors.message}
              errorText={translate(errors.message)}
            />
          </div>
          <div className={style.help_form__footer}>
            <div className={style.help_form__footer_checkbox}>
              <Checkbox checked data-cy='help-argee-processing' theme='help_form' required>
                {
                  intl.formatMessage({
                    id: 'help.agreement',
                    defaultMessage: 'Нажимая на кнопку \'Отправить\' я даю согласие на обработку своих <a>персональных данных</a>'
                  }, {
                    a: (chunks) => <a href={baseSlug.pagePrivacyPolicy} target='_blank' rel='noreferrer'>{chunks}</a>
                  })
                }
              </Checkbox>
            </div>
            <Button
              full
              type='submit'
              className={style.help_form__footer_button}
              data-cy='help-send-quest'
            >
              {
                intl.formatMessage({
                  id: 'send',
                  defaultMessage: 'Отправить'
                })
              }
            </Button>
          </div>
        </GxForm>
      </div>
      <div className={style.help_banners}>
        <a
          href='https://www.youtube.com/channel/UCxF9ylvv-AHDG3uAaYkTawQ'
          target='blank'
          className={classNames(
            style.help_banners__banner,
            style['help_banners__banner--blue'],
            'disabled'
          )}
        >
          <GxIcon
            className={style.help_banners__image}
            src={youtubeImage}
          />
          <p className={style.help_banners__title}>{intl.formatMessage({
            id: 'youtubeChanel',
            defaultMessage: 'Youtube канал GLS'
          })}
          </p>
          <p className={style.help_banners__text}>
            {
              intl.formatMessage({
                id: 'youTubeDescription',
                defaultMessage: 'Обучающие видео и другая полезная информация'
              })
            }
          </p>
        </a>
        <div className={style.help_banners__banner}>
          <GxIcon
            className={style.help_banners__image}
            src={supportImage}
          />
          <a
            href={`tel:${hrefPhone}`}
            className={style.help_banners__phone}
          >
            {formattedPhone}
          </a>
          <p className={style.help_banners__text}>
            {
              intl.formatMessage({
                id: 'help.GLS',
                defaultMessage: 'По всем вопросам связанным с использованием Garpix load system'
              })
            }
          </p>
        </div>
      </div>
    </div>
  )
}
export default memo(HelpSendQuestion)
