import { useFormik } from 'formik'
import React from 'react'
import { PasswordSchema } from '../../../utils/ValidateSchemes'
import { convertError } from '../../../utils'
import ChangePassword from '../../../views/BaseModal/ModalContent/ChangePassword'
import { useModalIntlContent, useModalStore } from '../../../hooks/Modals'
import { userApiService } from '../../../api/services'
import { runtimeConfig } from '../../../config'
import { useSocket } from '../../../apps/hooks'
import api from '../../../api'
import useTranslate from '../../../hooks/useTranslate'

const MAIN_URL_WSS = runtimeConfig.API_URL_WSS
const ChangePasswordModal = ({ handleClose }) => {
  const { openModal, contentTypes } = useModalStore()
  const { init, update, socket } = useSocket()
  const errorModalContent = useModalIntlContent(contentTypes.warning)

  const { translate } = useTranslate()

  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      password2: ''
    },
    onSubmit: async (values, { setErrors }) => {
      try {
        const { access_token: token } = await userApiService.setPassword(values)
        api.setAuthToken(token)
        socket.close()
        const initSocket = init(MAIN_URL_WSS, token)
        update(initSocket)
        handleClose()
      } catch (e) {
        if (e.response) {
          const errors = e.response?.data
          setErrors(errors)
        } else {
          const error = convertError(e)
          openModal('commonConfirm', { ...errorModalContent, description: translate(error) })
        }
      }
    },
    validationSchema: PasswordSchema
  })

  return (
    <ChangePassword handleClose={handleClose} formik={formik} />
  )
}

export default ChangePasswordModal
