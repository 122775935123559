import React from 'react'
import style from '../styles/index.module.scss'
import Header from '../../../components/Table/Header'
import Body from './Body'
import { containerIcon, palletIcon, truckIcon, vanIcon } from '../../../images'
import useTranslate from '../../../hooks/useTranslate'

const headers = {
  cargo: [
    { id: 1, title: { id: 'title', defaultMessage: 'Название' }, ordering: 'title', sort: true, width: '30%' },
    { id: 2, title: { id: 'length', defaultMessage: 'Длина' }, ordering: 'length', sort: true },
    { id: 3, title: { id: 'width', defaultMessage: 'Ширина' }, ordering: 'width', sort: true },
    { id: 4, title: { id: 'height', defaultMessage: 'Высота' }, ordering: 'height', sort: true },
    { id: 5, title: { id: 'mass', defaultMessage: 'Масса' }, ordering: 'mass', sort: true },
    { id: 6, title: { id: 'created', defaultMessage: 'Создано' }, ordering: 'created_at', sort: true },
    { id: 7, title: { id: 'changed', defaultMessage: 'Изменено' }, ordering: 'updated_at', sort: true },
    { id: 8, title: { id: 'owner', defaultMessage: 'Владелец' }, ordering: 'project_owner', sort: false },
    { id: 9, title: '', ordering: 'demo', sort: false, width: '60px' }
  ],
  cargo_space: [
    { id: 1, title: { id: 'title', defaultMessage: 'Название' }, ordering: 'title', sort: true, width: '50%' },
    { id: 2, title: { id: 'type', defaultMessage: 'Тип' }, ordering: 'cargo_space_type', sort: true },
    { id: 3, title: { id: 'created', defaultMessage: 'Создано' }, ordering: 'created_at', sort: true },
    { id: 4, title: { id: 'changed', defaultMessage: 'Изменено' }, ordering: 'updated_at', sort: true },
    { id: 5, title: { id: 'owner', defaultMessage: 'Владелец' }, ordering: 'project_owner', sort: false },
    { id: 6, title: '', ordering: 'demo', sort: false, width: '60px' }
  ],
  project: [
    { id: 1, title: { id: 'title', defaultMessage: 'Название' }, ordering: 'title', sort: true, width: '55%' },
    { id: 2, title: { id: 'created', defaultMessage: 'Создано' }, ordering: 'created_at', sort: true },
    { id: 3, title: { id: 'changed', defaultMessage: 'Изменено' }, ordering: 'updated_at', sort: true },
    { id: 4, title: { id: 'owner', defaultMessage: 'Владелец' }, ordering: 'project_owner', sort: false },
    { id: 5, title: { id: 'rated', defaultMessage: 'Оценено' }, ordering: 'is_all_grades', sort: false },
    { id: 6, title: '', ordering: 'demo', sort: false, width: '60px' }
  ]
}

const iconCS = {
  truck: truckIcon,
  van: vanIcon,
  container: containerIcon,
  pallet: palletIcon
}

const Table = ({ type, company, hasRatingColumn, ...props }) => {
  const { translate } = useTranslate()
  let header = headers[type].map(header => ({ ...header, title: translate(header.title) }))

  if (!company && type === 'project') {
    header = header.filter(el => el.ordering !== 'project_owner')
  }
  if (type === 'project' && !hasRatingColumn) {
    header = header.filter(el => el.ordering !== 'is_all_grades')
  }

  return (
    <div className={style.table_wrap}>
      <table className={style.table}>
        <Header headers={header} type={type} {...props} />
        <Body
          type={type}
          iconCS={iconCS}
          headers={header}
          company={company}
          {...props}
        />
      </table>
    </div>
  )
}

export default Table
