/* eslint-disable camelcase */
import React from 'react'
import ProfileViews from '../../views/ProfileViews'
import { useModalIntlContent, useModalStore } from '../../hooks/Modals'
import { serializedTariff } from '../../api/serializers'
import { TariffApiService, userApiService } from '../../api/services'

const UserCurrentTariff = ({ currentTariff, setCurrentTariff, isProfile }) => {
  const { openModal, contentTypes, closeModal } = useModalStore()
  const cancelTariffModalContent = useModalIntlContent(contentTypes.cancelTariff)

  const cancelTariff = async () => {
    await TariffApiService.cancelTariff()
    const { current_tariff } = await userApiService.getCurrentUser()
    setCurrentTariff(serializedTariff(current_tariff))
    closeModal()
  }

  const handleCancelModal = () => {
    openModal('commonConfirm', { ...cancelTariffModalContent, handleAccept: cancelTariff })
  }

  if (!currentTariff) return null

  return (
    <ProfileViews.UserCurrentTariff
      currentTariff={currentTariff}
      cancelModal={handleCancelModal}
      isProfile={isProfile}
    />
  )
}
export default UserCurrentTariff
