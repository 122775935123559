import * as yup from 'yup'
import { INTL_ERROR_MESSAGE } from '../../const'

const RecoveryEmailSchema = yup.object().shape({
  email: yup
    .string()
    .email(INTL_ERROR_MESSAGE.EMAIL)
    .required(INTL_ERROR_MESSAGE.REQUIRED)
})

const RecoveryCodeSchema = yup.object().shape({
  code: yup.string().required(INTL_ERROR_MESSAGE.REQUIRED)
})

const RecoveryPasswordSchema = yup.object().shape({
  password: yup.string().trim().required(INTL_ERROR_MESSAGE.REQUIRED),
  repassword: yup
    .string()
    .trim()
    .oneOf([yup.ref('password')], INTL_ERROR_MESSAGE.MATCH_PASS)
    .required(INTL_ERROR_MESSAGE.REQUIRED)
})

export default {
  RecoveryEmailSchema,
  RecoveryCodeSchema,
  RecoveryPasswordSchema
}
