import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import Button from '../../../../views/Button'

import styles from './style/index.module.scss'
import usePage from '../../../../hooks/usePage'
import { BUTTON_THEME } from '../../../../const'

const UseCookiesAlertView = ({ handleAccess, isOpen }) => {
  const { baseSlug } = usePage()
  const intl = useIntl()
  return (
    <aside data-open={isOpen} className={styles.cookies}>
      <div className={styles.cookies__wrap}>
        <p className={styles.cookies__text}>
          {intl.formatMessage({
            id: 'cookieMessage',
            defaultMessage: 'Сайт использует файлы cookie для улучшения работы. Продолжая пользоваться сайтом, {br} вы соглашаетесь с <a>условиями.</a>'
          }, {
            a: (chunk) => <Link to={baseSlug.pagePrivacyPolicy}>{chunk}</Link>,
            br: () => <br />
          })}
        </p>
        <Button theme={BUTTON_THEME.FILL_ICON.SMALL} onClick={handleAccess} className={styles.cookies__btn}>
          {intl.formatMessage({
            id: 'accept',
            defaultMessage: 'Принять'
          })}
        </Button>
      </div>
    </aside>
  )
}

export default UseCookiesAlertView
