import { Locator } from '../locator'
import asyncComponent from '../../components/asyncComponent'
import HomePage from '../../pages/Home'

const importPage = (pageName) => () => {
  return import(`../../pages/${pageName}`)
}

const pageRegistry = new Locator()
pageRegistry.register('CompanyPage', asyncComponent(importPage('Company')))
pageRegistry.register('StatisticPage', asyncComponent(importPage('Statistics')))
pageRegistry.register('NewsPage', asyncComponent(importPage('News')))
pageRegistry.register('NewsListPage', asyncComponent(importPage('NewsList')))
pageRegistry.register('NotificationsListPage', asyncComponent(importPage('Notifications')))
pageRegistry.register('LandingPage', HomePage)
pageRegistry.register('ApiDocumentationPage', asyncComponent(importPage('ApiDocumentation')))
pageRegistry.register('ProfilePage', asyncComponent(importPage('ProfilePage')))
pageRegistry.register('TariffsPage', asyncComponent(importPage('TariffsPage')))
pageRegistry.register('HelpPage', asyncComponent(importPage('HelpPage')))
pageRegistry.register('PromoCodesPage', asyncComponent(importPage('Promocodes')))
pageRegistry.register('RegistrationPage', asyncComponent(importPage('Registration')))
pageRegistry.register('LoginPage', asyncComponent(importPage('Authorization')))
pageRegistry.register('PasswordRecoveryPage', asyncComponent(importPage('RestorePassword')))

pageRegistry.register('CompanyPage', asyncComponent(importPage('Company')))

pageRegistry.register('RESTORE_PASSWORD_SET_PASSWORD', asyncComponent(importPage('SetRestorePassword')))

pageRegistry.register('InfoPage', asyncComponent(importPage('Info')))

pageRegistry.register('CargosListPage', asyncComponent(importPage('Cargo')))
pageRegistry.register('CreateCargoPage', asyncComponent(importPage('CargoCreate')))
pageRegistry.register('CargoSpacesListPage', asyncComponent(importPage('CargoSpace')))
pageRegistry.register('CreateCargoSpacePage', asyncComponent(importPage('CargoSpaceCreate')))
pageRegistry.register('UpdateCargoSpacePage', asyncComponent(importPage('CargoSpaceUpdate')))
pageRegistry.register('UpdateCargoPage', asyncComponent(importPage('CargoUpdate')))

pageRegistry.register('FAIL', asyncComponent(importPage('FailPay')))

pageRegistry.register('Project', asyncComponent(importPage('Project')))
pageRegistry.register('ProjectReportPage', asyncComponent(importPage('ProjectReport')))
pageRegistry.register('ProjectsListPage', asyncComponent(importPage('Projects')))
pageRegistry.register('404', asyncComponent(importPage('Page404')))
pageRegistry.register('WellcomePage', asyncComponent(importPage('Greeting')))
pageRegistry.register('FaqPage', asyncComponent(importPage('FAQ')))
pageRegistry.register('PaymentPage', asyncComponent(importPage('Payment')))
pageRegistry.register('MailingPage', asyncComponent(importPage('Mailing')))
pageRegistry.register('SUCCESS', asyncComponent(importPage('SuccessPay')))

pageRegistry.register('VisualPage', asyncComponent(importPage('ReportVisual')))

pageRegistry.register('PaymentResultPage', asyncComponent(importPage('PaymentResult')))

export default pageRegistry
